<script>
import { Icon } from "@iconify/vue2";

import { BaseLogo } from "@/components/ui";

export default {
  name: "BaseModalContainer",
  components: {
    BaseLogo,
    Icon,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideFooter: {
      type: Boolean,
      required: false,
      default: true,
    },
    isBackgroundWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onHide(e) {
      if (e.trigger === "backdrop" || e.trigger === "esc") {
        this.$emit("on-hide");
      }
    },
  },
  emits: ["on-hide"],
};
</script>

<template>
  <b-modal
    :id="modalId"
    :title="title"
    :size="size"
    :hide-header="hideHeader"
    :hide-footer="hideFooter"
    centered
    scrollable
    @hide="onHide"
    @close="$emit('on-hide')"
    :body-class="{ backgroundWhite: isBackgroundWhite }"
    :header-class="{ backgroundWhite: isBackgroundWhite }"
  >
    <template #modal-header="{ close }">
      <div class="logo"><BaseLogo /></div>

      <button class="closeBtn" @click.prevent="close">
        <Icon icon="bx-x" height="100%" width="100%" />
      </button>
    </template>

    <slot></slot>

    <template #modal-footer>
      <slot name="footer"></slot>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";
@import "@/style/colors.scss";
@import "@/style/breakpoints.scss";

:deep(.modal > .modal-dialog) {
  @include for-phone-only {
    margin: 0;
    height: 100vh;
  }
}

:deep(.modal > .modal-dialog > .modal-content) {
  height: 100%;
  border-radius: 16px;
  background-color: $primary-background;

  @include for-phone-only {
    border-radius: 0;
    height: 100%;
  }
}

:deep(.modal > .modal-dialog-scrollable) {
  @include for-phone-only {
    max-height: 100%;
  }
}

:deep(.modal > .modal-dialog > .modal-content > .modal-header) {
  min-height: 50px;
  border-bottom: none;
  display: grid;
  grid-template-columns: 1fr 30px;
  padding: 20px;

  & .modal-title {
    @include raleway-semibold;
    text-align: center;
    font-size: 28px;
    line-height: 30px;
  }

  & .logo {
    display: none;

    @include for-phone-only {
      display: block;
    }
  }

  & .closeBtn {
    width: 30px;
    height: 30px;
    color: $secondary-light-grey;
    grid-column: 2 / 3;
  }
}

:deep(.modal > .modal-dialog > .modal-content > .modal-header > .close) {
  height: 50px;
  width: 50px;
  padding: 0;
  color: $secondary-light-grey;
}

:deep(.modal > .modal-dialog > .modal-content > .modal-body) {
  padding-top: 0px;

  @include for-phone-only {
    height: 100%;
  }
}

:deep(.modal > .modal-dialog > .modal-content > .modal-footer) {
  padding: 20px;
}
</style>

<style lang="scss">
@import "@/style/colors.scss";

.backgroundWhite {
  background-color: $primary-white;
}
</style>