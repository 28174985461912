var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BaseCircleButton',{attrs:{"customStyle":{
      height: `${_vm.size}px`,
      width: `${_vm.size}px`,
      backgroundColor: _vm.colors.primaryBackground,
      position: 'relative',
      overflow: 'visible',
    }},on:{"click":function($event){return _vm.$emit('on-click')}}},[(_vm.unreadMsgNumber)?_c('div',{staticClass:"indicator"},[_vm._v(" "+_vm._s(_vm.formattedUnreadNumber)+" ")]):_vm._e(),_c('Icon',{attrs:{"icon":"bxs-message-rounded","color":_vm.colors.primaryBlack,"width":_vm.iconSize,"height":_vm.iconSize}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }