<script>
import { Icon } from '@iconify/vue2';

import { WaitState } from '@/values/waitState';

export default {
  name: 'CampaignInfluencersTableSaveIndicator',
  components: { Icon },
  props: {
    status: { type: String, required: false, default: WaitState.default },
  },
  computed: {
    currentState() {
      return this.stateMap[this.status];
    },
    stateMap() {
      return {
        [WaitState.isLoading]: {
          className: '',
          message: this.$gettext('Saving data'),
          icon: 'svg-spinners:3-dots-bounce',
        },
        [WaitState.isSuccess]: {
          className: '',
          message: this.$gettext('Changes were saved successfully.'),
          icon: '',
        },
        [WaitState.isFailure]: {
          className: 'failure',
          message: this.$gettext('Changes were not saved.'),
          icon: '',
        },
        [WaitState.isOutdated]: {
          className: 'failure',
          message: this.$gettext('Data is outdated.'),
          icon: '',
        },
        [WaitState.default]: {
          className: '',
          message: '',
          icon: '',
        },
      };
    },
  },
};
</script>

<template>
  <div class="save-indicator" v-bind="$attrs">
    <div v-if="currentState.message" :class="['save-indicator__container', currentState.className]">
      <Icon v-if="currentState.icon" :icon="currentState.icon" style="margin-top: 2px" />
      <span>{{ currentState.message }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .save-indicator {
    @include fonts.raleway-medium;
    @include fonts.fontSize-big;
    user-select: none;

    &__container {
      display: flex;
      align-items: center;
      gap: 10px;
      color: colors.$primary-grey-light;

      &.failure {
        color: colors.$secondary-red;
      }
    }
  }
</style>
