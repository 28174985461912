var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type === 'link')?_c('div',{staticClass:"section"},[_c('div',{staticClass:"section-heading"},[_c('span',{staticClass:"section-subtitle"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),(_vm.admin)?_c('TableContentLink',{staticClass:"section-link",attrs:{"link":_vm.link},on:{"update:link":_vm.updateLink}}):_vm._e(),(!_vm.admin)?_c('a',{staticClass:"link",attrs:{"href":_vm.link,"target":"_blank"}},[_c('Icon',{staticClass:"link-icon",attrs:{"color":_vm.colors.primaryGrey,"icon":"bx-link-alt"}}),_c('span',{staticClass:"link-text"},[_vm._v(_vm._s(_vm.link))]),_c('Icon',{staticClass:"link-icon",attrs:{"color":_vm.colors.primaryBlue,"icon":"bx-link-external"}})],1):_vm._e()],1):_vm._e(),(_vm.type === 'content')?_c('div',{staticClass:"section"},[(_vm.admin || _vm.items.length)?_c('div',{staticClass:"section-heading"},[_c('div',{staticClass:"section-heading"},[_c('span',{staticClass:"section-subtitle"},[_vm._v(_vm._s(_vm.title))]),(_vm.items.length)?_c('span',{staticClass:"section-amount"},[_vm._v(_vm._s(_vm.items.length))]):_vm._e(),_c('button',{on:{"click":_vm.addContent}},[(_vm.admin)?_c('Icon',{staticClass:"section-add",attrs:{"icon":"bx:plus"}}):_vm._e()],1)]),(_vm.items.length)?_c('div',{staticClass:"navigation"},[_c('button',{staticClass:"navigation-btn",on:{"click":_vm.showPrevious}},[_c('Icon',{attrs:{"color":"black","icon":"bx-chevron-left","width":"20"}})],1),_c('button',{staticClass:"navigation-btn",on:{"click":_vm.showNext}},[_c('Icon',{attrs:{"color":"black","icon":"bx-chevron-right","width":"20"}})],1)]):_vm._e()]):_vm._e(),(_vm.items.length)?_c('vueper-slides',{ref:_vm.refName,staticClass:"no-shadow",attrs:{"arrows":false,"breakpoints":{
        570: { visibleSlides: 4, slideMultiple: 2 },
        640: { visibleSlides: 3, slideMultiple: 2 },
        768: { visibleSlides: 3, slideMultiple: 2 },
        991: { visibleSlides: 4, slideMultiple: 3 },
        1200: { visibleSlides: 6, slideMultiple: 4 },
        2560: { visibleSlides: 8, slideMultiple: 4 },
      },"bullets":false,"dragging-distance":200,"gap":1,"touchable":false,"visible-slides":8.5,"slide-multiple":""}},_vm._l((_vm.items),function(item){return _c('vueper-slide',{key:item.id,attrs:{"image":item.attachment,"link":item.attachment,"open-in-new":true},scopedSlots:_vm._u([(item.attachment)?{key:"content",fn:function(){return [_c('div',{staticClass:"custom-slide"},[(item.type === 'video')?_c('img',{staticClass:"custom-slide-icon",attrs:{"src":require('@/assets/play.png'),"alt":"video"}}):_vm._e(),(_vm.admin)?_c('button',{staticClass:"close-button",on:{"click":function($event){return _vm.removeSlide($event, item.id)}}},[_c('Icon',{attrs:{"icon":"bx:bx-x"}})],1):_vm._e()])]},proxy:true}:null],null,true)})}),1):_vm._e(),(_vm.admin & !_vm.items.length)?_c('div',{staticClass:"section no-content"},[_c('div',{staticClass:"no-content__header"},[_c('Icon',{attrs:{"icon":"ci:note-search","width":"24"}}),_c('span',[_c('translate',[_vm._v("No")]),_vm._v(" "+_vm._s(_vm.getText(_vm.title))+" ")],1)],1),_c('span',{staticClass:"no-content__description"},[_c('translate',[_vm._v("Add")]),_vm._v(" "+_vm._s(_vm.title.toLowerCase())+" ")],1)]):_vm._e()],1):_vm._e(),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"id":"formFile","accept":"image/*,video/*","type":"file"},on:{"input":_vm.addAttachment}})])
}
var staticRenderFns = []

export { render, staticRenderFns }