<script>
export default {
  name: 'BaseSwitch',
  props: {
    checked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    switchId: { type: String, required: true },
  },
  emits: ['update:checked'],
  methods: {
    handleChange(event) {
      this.$emit('update:checked', event.target.checked);
    },
  },
};
</script>

<template>
  <div>
    <span class="switch">
      <input
        :id="switchId"
        :checked="checked"
        :disabled="disabled"
        type="checkbox"
        @change="handleChange"
      >
      <label :for="switchId" />
    </span>
  </div>
</template>

<style lang="scss" scoped>
  .switch {
    cursor: pointer;
    position: relative;

    input {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    label {
      display: flex;
      align-items: center;
      background: #cfd4d9;
      width: 23px;
      height: 14px;
      border-radius: 15px;
      padding-left: 3px;
      cursor: pointer;
      transition: 0.3s;
      margin: 0;
      border: 2px solid #cfd4d9;

      &::after {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 15px;
        background: white;
        transition: 0.3s;
      }
    }

    input:checked + label {
      background: #825af9;
      border: 2px solid #825af9;

      &::after {
        transform: translateX(7px);
        background: white;
      }
    }

    input:disabled + label {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
</style>
