<script>
import { Icon } from '@iconify/vue2';
import { createNamespacedHelpers } from 'vuex';

import ModalError from '@/components/menu/ModalError';
import { BaseViewButton, Loading } from '@/components/ui';
import colors from '@/style/colors';
import { ModalName } from '@/values/modalName';

const { mapActions } = createNamespacedHelpers('campaignModule');

export default {
  name: 'TaskListModalBody',
  components: {
    BaseViewButton,
    Icon,
    Loading,
    ModalError,
  },
  props: {
    items: { type: Array, required: false, default: () => [] },
    bloggers: { type: [Array, Object], required: false, default: () => [] },
    taskId: { type: Number, required: false, default: null },
  },
  emits: ['update-state', 'on-empty-state', 'on-redirect'],
  data: () => ({
    ModalName,
    state: 'isLoading',
    taskList: null,
    campaignId: null,
    errorModalId: 'errorModal',
    selectedTaskId: '',
  }),
  computed: {
    colors() {
      return colors;
    },
  },
  watch: {
    taskId: {
      immediate: true,
      handler(newValue) {
        this.selectedTaskId = newValue;
      },
    },
  },
  created() {
    this.campaignId = this.$route.params.id;
    this.getState();
  },
  methods: {
    ...mapActions({
      getTechnicalDescriptionList: 'getTechnicalDescriptionList',
    }),
    async getState() {
      this.state = 'isLoading';
      try {
        const response = await this.getTechnicalDescriptionList({ campaignId: this.campaignId });
        this.taskList = response.data.filter((x) => x.status === 'published');
        this.taskList.length === 0 ? this.$emit('on-redirect') : (this.state = 'isSuccess');
      } catch {
        this.state = 'isError';
        this.$bvModal.show(this.errorModalId);
      }
    },
    handleAttachBtnClick() {
      if (this.selectedTaskId !== this.taskId) {
        this.state = 'isLoading';
        this.$emit('update-state', {
          taskId: this.selectedTaskId,
          bloggers: this.bloggers,
        });
      }
    },
    handleCheckboxClick(taskId) {
      this.selectedTaskId = taskId;
    },
  },
};
</script>

<template>
  <div class="tl-modal-body">
    <ModalError :modal-id="errorModalId" @cancel="$bvModal.hide(errorModalId)" />

    <div v-if="state === 'isLoading'" class="tl-modal-body__loading">
      <div class="tl-modal-body__loading">
        <Loading type="round" />
      </div>
    </div>

    <div v-if="state === 'isSuccess'" class="tl-modal-body__wrapper">
      <h2 class="tl-modal-body__title">
        <translate>Attach technical description to the offer</translate>
      </h2>
      <div class="tl-modal-body__content">
        <div
          v-for="task in taskList"
          :key="task.id"
          :class="{ 'tl-modal-body__task-item--selected': selectedTaskId === task.id }"
          class="tl-modal-body__task-item"
          @click.prevent="handleCheckboxClick(task.id)"
        >
          <Icon
            v-if="selectedTaskId === task.id"
            :color="colors.brandColor"
            class="tl-modal-body__task-item-icon"
            icon="bx-radio-circle-marked"
          />
          <Icon
            v-else
            :color="colors.secondaryWhiteGrey"
            class="tl-modal-body__task-item-icon"
            icon="bx-radio-circle"
          />
          <span class="tl-modal-body__task-item-name">{{ task.name }}</span>
        </div>
      </div>

      <BaseViewButton
        :disabled="selectedTaskId === taskId"
        :text="$gettext('Attach task')"
        class="tl-modal-body__attach-btn"
        @on-click="handleAttachBtnClick"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;
  @use '@/style/breakpoints.scss' as breakpoints;

  .tl-modal-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__loading {
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      background-color: colors.$primary-background;
      opacity: 0.7;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;
      width: 100%;

      @include breakpoints.for-phone-only {
        padding: 0;
      }
    }

    &__title {
      @include fonts.raleway-bold;
      font-size: 28px;
      line-height: 36px;
      color: colors.$primary-black;
      margin: 0;
      text-align: center;
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }

    &__task-item {
      @include fonts.raleway-semibold;
      height: 54px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      font-size: 16px;
      padding: 0 20px;
      border-radius: 8px;
      background-color: colors.$primary-white;

      &:hover {
        cursor: pointer;
      }

      &--selected {
        background-color: colors.$primary-lavender;
      }
    }

    &__task-item-icon {
      width: 24px;
      min-width: 24px;
      height: 24px;
    }

    &__task-item-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__attach-btn {
      align-self: flex-end;
      padding: 0 50px;
    }
  }
</style>
