<script>
import moment from "moment";

export default {
  name: "TaskTemplate",
  props: {
    data: { type: Object, default: () => ({}) },
  },
  computed: {
    date() {
      const start = this.data.start_date;
      const end = this.data.end_date;
      return start && end
        ? `${moment(start).format("DD.MM.YYYY")} / ${moment(end).format("DD.MM.YYYY")}`
        : "";
    },
    name() {
      return this.data.name ?? "";
    },
    description() {
      return this.data.description ?? "";
    },
    mustHave() {
      return this.data.must_have ?? "";
    },
    mustNotHave() {
      return this.data.must_not_have ?? "";
    },
  },
};
</script>

<template>
  <section class="template">
    <header class="template__header">
      <h1 class="template__title text-wrap">{{ name }}</h1>
      <p class="template__date text-nowrap">{{ date }}</p>
    </header>

    <main class="template__main">
      <section v-if="description" class="template__container template--full">
        <translate class="template__container-title" tag="h2">
          General info
        </translate>
        <p class="template__container-text">{{ description }}</p>
      </section>
      <section v-if="mustHave" :class="['template__container', !mustNotHave && 'template--full']">
        <translate class="template__container-title" tag="h2">
          Must haves
        </translate>
        <p class="template__container-text">{{ mustHave }}</p>
      </section>
      <section v-if="mustNotHave" :class="['template__container', !mustHave && 'template--full']">
        <translate class="template__container-title" tag="h2">
          Must not haves
        </translate>
        <p class="template__container-text">{{ mustNotHave }}</p>
      </section>
    </main>
  </section>
</template>

<style lang="scss" scoped>
@use "@/style/fonts.scss" as fonts;
@use "@/style/colors.scss" as colors;

.template {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  color: colors.$primary-black;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    @include fonts.raleway-bold;
    font-size: 28px;
    line-height: 32.87px;
  }

  &__date {
    @include fonts.raleway-medium;
    font-size: 14px;
    line-height: 20px;
    padding: 14px 25px;
    background: colors.$primary-background;
    border-radius: 16px;
  }

  &__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    background: colors.$primary-background;
    border-radius: 16px;
    min-height: 200px;

    &-title {
      @include fonts.raleway-bold;
      font-size: 16px;
      line-height: 18.78px;
    }

    &-text {
      @include fonts.raleway-regular;
      font-size: 14px;
      line-height: 20px;
    }

    &.template--full {
      grid-column: 1 / span 2;
    }
  }
}
</style>