<script>
import Placeholder from '@/assets/blogger-img3.png';

export default {
  name: 'BaseAvatar',
  props: {
    as: { type: String, default: 'div' },
    src: { type: String, default: '' },
  },
  emits: ['click'],
  data: () => ({
    source: Placeholder,
  }),
  watch: {
    src: {
      handler(src) {
        this.source = src;
      },
      immediate: true,
    },
  },
  methods: {
    onError() {
      this.source = Placeholder;
    },
  },
};
</script>

<template>
  <component :is="as" class="avatar" v-bind="$attrs" @click="$emit('click', $event)">
    <img :src="source" alt="avatar" @error="onError">
  </component>
</template>

<style lang="scss" scoped>
  .avatar {
    width: 50px;
    height: 50px;
    aspect-ratio: 1;

    & > img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
    }
  }
</style>
