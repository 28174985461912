<script>
import { Icon } from "@iconify/vue2";
import colors from "@/style/colors";

export default {
  name: "BaseTimer",
  components: {
    Icon,
  },
  props: {
    size: {
      type: Number,
      required: false,
      default: 12,
    },
    hours: {
      type: Number,
      required: true,
    },
  },
  data() {
    return { colors: colors };
  },
};
</script>

<template>
  <div class="timer">
    <Icon
      icon="bx-time-five"
      :color="colors.brandColor"
      :width="size"
      :height="size"
    />
    <span class="timer-value" :style="{ fontSize: `${size}px` }">
      {{ hours }}<translate>h</translate>
    </span>
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/colors.scss";
@import "@/style/fonts.scss";

.timer {
  @include raleway-medium;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
  color: $brand-color;
  line-height: 20px;

  &-value {
    white-space: nowrap;
  }
}
</style>