<script>

import Loading from "@/components/ui/Loading.vue";

export default {
  name: "BaseForm",
  components: { Loading },
  props: {
    loading: { type: Boolean, default: false },
  },
  emits: ["submit"],
};
</script>

<template>
  <form class='base-form' @submit.prevent="$emit('submit', $event)">
    <div v-if="loading" class="base-form__loader">
      <Loading />
    </div>

    <slot></slot>
  </form>
</template>

<style lang="scss" scoped>
@use "@/style/colors.scss" as colors;

.base-form {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(colors.$primary-white, 0.75);
    pointer-events: auto;
    z-index: 1;
  }
}

@media screen and (max-width: var(--advy-form-sm-width, 768px)) {
  .base-form {
    gap: 16px;
  }
}
</style>