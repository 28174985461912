export const isSecondDateLater = (startDate, endDate) => {
  const transformedStartDate = new Date(startDate)
  const transformedEndDate = new Date(endDate)

  return transformedStartDate < transformedEndDate
}

export const formatDateString = inputDateString => {
  const dateObject = new Date(inputDateString)

  const year = dateObject.getFullYear()
  const month = String(dateObject.getMonth() + 1).padStart(2, '0')
  const day = String(dateObject.getDate()).padStart(2, '0')
  const formattedDateString = `${year}-${month}-${day}`

  return formattedDateString
}
