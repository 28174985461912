<script>
import { ErrorCode } from "@/values/errors";
import { Icon } from "@iconify/vue2";
import BaseButton from "@/components/ui/BaseButton.vue";

export default {
  name: "BaseNotificationProvider",
  components: { BaseButton, Icon },
  data: () => ({ ErrorCode }),
  methods: {
    getIcon(type) {
      if (type === "error") return "bi:ban";
      if (type === "success") return "bi:check-circle";
      return "";
    },
  },
};
</script>

<template>
  <notifications
    :duration="8000"
    :max="3"
    class="base-notification__container"
    group="app"
    position="bottom right"
    width="480px"
  >
    <template slot="body" slot-scope="{ item, close }">
      <div :class="['base-notification', `base-notification--${item.type}`]">
        <Icon
          v-if="getIcon(item.type)"
          :icon="getIcon(item.type)"
          class="base-notification__icon"
        />

        <div class="base-notification__content">
          <div v-if="item.title">{{ item.title }}</div>
          <div>
            <span v-if="item.text">{{ item.text }}</span>

            <template v-if="item.type === 'error' && item.data.code">
              <template v-if="item.data.code === ErrorCode.PaymentError">
                <translate>Insufficient funds</translate>
              </template>
            </template>
          </div>
        </div>

        <button class="base-notification__close" @click="close">
          <Icon class="base-notification__close-icon" icon="bi:x-lg" />
        </button>
      </div>
    </template>
  </notifications>
</template>

<style lang="scss" scoped>
@use "@/style/fonts.scss" as fonts;
@use "@/style/colors.scss" as colors;

.base-notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  padding: 20px;
  margin: 8px 0;
  min-height: 74px;
  border-radius: 12px;
  border-top: 2px solid colors.$brand-color;
  background: colors.$primary-white;
  color: colors.$primary-black;

  @include fonts.fontSize-big;
  @include fonts.raleway-medium;

  &__container {
    padding: 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__icon {
    width: 18px;
    height: 18px;
    color: colors.$brand-color;
  }

  &__close {
    padding: 3px;
    color: colors.$primary-grey-light;
    transition: all 0.3s;

    &-icon {
      width: 18px;
      height: 18px;
    }

    &:hover,
    &:active {
      color: colors.$primary-black;
    }
  }

  &.base-notification--error {
    border-top: 2px solid colors.$primary-red;

    .base-notification__icon {
      color: colors.$primary-red;
    }
  }
}
</style>
