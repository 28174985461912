<template>
  <div class="base-range-input">
    <BaseInput
      :disabled="disabled"
      :pattern="pattern"
      :placeholder="placeholderFrom"
      :value="valueFrom"
      class="base-range-input__field"
      name="range-from"
      type="number"
      @input="$emit('update:valueFrom', $event)"
    />

    <BaseInput
      :disabled="disabled"
      :pattern="pattern"
      :placeholder="placeholderTo"
      :value="valueTo"
      class="base-range-input__field"
      name="range-to"
      type="number"
      @input="$emit('update:valueTo', $event)"
    />
  </div>
</template>

<script>
import BaseInput from '@/components/ui/BaseInput.vue';

export default {
  name: 'BaseRangeInput',
  components: { BaseInput },
  props: {
    disabled: { type: Boolean, default: false },
    pattern: { type: String, default: '[0-9]+([\\.][0-9]+)?' },
    valueFrom: { type: String, default: '' },
    valueTo: { type: String, default: '' },
    placeholderFrom: { type: String, default: '' },
    placeholderTo: { type: String, default: '' },
  },
  emits: ['update:valueFrom', 'update:valueTo'],
};
</script>

<style lang="scss" scoped>
  @use '@/style/fonts';

  .base-range-input {
    display: flex;
    align-items: center;
    gap: 5px;

    &__field {
      width: 135px;
    }
  }
</style>
