const ModalName = {
  CampaignCreationModal: 'CampaignCreationModal',
  InfluencerPublicViewModal: 'InfluencerPublicViewModal',
  AuthModal: 'authModal',
  ContentModal: 'contentModal',
  TaskListModal: 'taskListModal',
  NotesModal: 'notesModal',
  TaskManagerModal: 'taskManager',
  MultipleEditingModal: 'multipleEditingModal',
  RemoveBloggerModal: 'removeBloggerModal',
  FailureModal: 'failureModal',
  OutdatedModal: 'outdatedModal',
  TableContentModal: 'tableContentModal',
  DeleteConfirmModal: 'deleteConfirmModal',
  BloggerModal: 'bloggerModal',
  AddBloggerModal: 'addBloggerModal',
};

Object.freeze(ModalName);

export { ModalName };
