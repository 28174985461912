<script>
import { Icon } from "@iconify/vue2";

export default {
  components: {
    Icon,
  },
  props: {
    customStyle: {
      type: Object,
      required: false,
    },
  },
  emits: ["click"],
};
</script>

<template>
  <button class="circle-btn" :style="customStyle" @click="$emit('click')">
    <slot></slot>
  </button>
</template>


<style scoped lang="scss">
@import "@/style/colors.scss";

.circle-btn {
  height: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-white;
  border-radius: 50%;
  padding: 0px;
  border: none;
  overflow: hidden;
  transition: 0.3s;

  &:not(:disabled):active,
  &:not(:disabled):hover {
    background-color: $primary-background-dark !important;
  }
}
</style>
