<script>
import { Icon } from "@iconify/vue2";
import colors from "@/style/colors";

export default {
  components: {
    Icon,
  },
  props: {
    isIconFilled: {
      type: Boolean,
      required: false,
    },
    size: {
      type: Number,
      required: false,
      default: 20,
    }
  },
  data() {
    return {
      colors: colors,
    };
  },
  emits: ["on-click"],
};
</script>

<template>
  <button class="button" @click.prevent="$emit('on-click')">
    <Icon
      v-if="isIconFilled"
      icon="bxs-bookmark"
      :width="size"
      :height="size"
      :color="colors.brandColor"
    />
    <Icon
      v-else
      icon="bx-bookmark"
      :width="size"
      :height="size"
      :color="colors.secondaryWhiteGrey"
    />
  </button>
</template>

<style lang="scss" scoped>
.button {
  margin: 0;
  padding: 0;

  & .icon {
    width: 100%;
    height: 100%;
  }
}
</style>