<script>
import { autoUpdate, computePosition, flip, offset, shift } from '@floating-ui/dom';
import { Icon } from '@iconify/vue2';

import TableCellPlaceholder from '@/components/ui/tables/TableCellPlaceholder.vue';

export default {
  name: 'SelectCellField',
  components: { TableCellPlaceholder, Icon },
  props: {
    value: { type: Object, required: false, default: null },
    options: { type: Array, required: true, default: () => [] },
    noChevron: { type: Boolean, default: false },
  },
  emits: ['submit', 'click'],
  data: () => ({
    isOpen: false,
    cleanUpFn: null,
  }),
  watch: {
    isOpen: {
      handler() {
        this.updateFloating();
      },
      immediate: true,
    },
  },
  created() {
    document.addEventListener('click', this.onClickOutside.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClickOutside.bind(this));
  },
  methods: {
    updateFloating() {
      this.$nextTick(() => {
        this.cleanUpFn && this.cleanUpFn();
        const targetRef = this.$refs.root;
        const floatingRef = this.$refs.positioner;

        if (!targetRef || !floatingRef) return;

        this.cleanUpFn = autoUpdate(targetRef, floatingRef, () => {
          computePosition(targetRef, floatingRef, {
            placement: 'bottom',
            middleware: [shift({ crossAxis: true }), offset(10), flip()],
          }).then(({ x, y }) => {
            Object.assign(floatingRef.style, { left: `${x}px`, top: `${y}px` });
          });
        });
      });
    },
    onContainerClick(e) {
      // Safari don't emit focus, when user click a button
      this.$refs.trigger?.focus();
      this.$emit('click', e);
    },
    onClickOutside({ target }) {
      if (!this.isOpen) return;
      if (!this.$refs.positioner?.contains(target) && !this.$refs.root?.contains(target)) {
        this.toggleOpen(false);
      }
    },
    toggleOpen(nextValue = !this.isOpen) {
      setTimeout(() => (this.isOpen = nextValue), 0);
    },
    onClose() {
      this.toggleOpen(false);
      this.cleanUpFn();
    },
    isActive(option) {
      return option.key === this.value?.key;
    },
  },
};
</script>

<template>
  <div ref="root" class="select-cell-field" v-bind="$attrs">
    <button
      ref="trigger"
      :aria-expanded="isOpen"
      :data-active="isOpen"
      class="select-cell-field__trigger"
      tabindex="0"
      @blur="onClose"
      @click="onContainerClick"
      @focus="toggleOpen(true)"
    >
      <template v-if="value?.text">
        <div class="select-cell-field__value">
          <slot name="value">
            {{ value?.text }}
          </slot>
        </div>

        <Icon
          v-if="!noChevron"
          :style="{ transform: isOpen ? 'rotate(180deg)' : '' }"
          aria-hidden="true"
          class="select-cell-field__icon"
          height="16"
          icon="bi:chevron-down"
          width="16"
        />
      </template>
      <TableCellPlaceholder v-else variant="select" />
    </button>

    <template v-if="isOpen">
      <portal to="positioner">
        <div ref="positioner" aria-modal="true" class="select-cell-field__positioner" role="dialog">
          <div class="select-cell-field__content">
            <template v-for="(option, index) of options">
              <div
                :key="option.key || index"
                :data-active="isActive(option)"
                :data-key="option.key || index"
                class="select-cell-field__item"
                @mousedown="$emit('submit', option)"
              >
                <Icon
                  v-if="isActive(option)"
                  class="select-cell-field__item-icon"
                  height="20"
                  icon="bi:check-lg"
                  width="20"
                />

                <span
                  v-else
                  class="select-cell-field__item-icon"
                  style="width: 20px; height: 20px"
                />

                <div class="select-cell-field__item-content">
                  <slot :active="isActive(option)" :option="option" name="option">
                    {{ option.text }}
                  </slot>
                </div>
              </div>
            </template>
            {{ '' }}
          </div>
        </div>
      </portal>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .select-cell-field {
    @include fonts.fontSize-big;
    @include fonts.raleway-medium;

    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    color: colors.$primary-black;

    &__trigger {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      flex-grow: 1;
      padding: 14px 10px;
    }

    &__value {
      flex-grow: 1;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__icon {
      color: colors.$secondary-white-grey;
    }

    &__positioner {
      position: fixed;
      z-index: 99;
      top: 170%;
      left: -20px;
      isolation: isolate;
      height: min-content;
      width: min-content;
      background: colors.$primary-white;
      box-shadow: 1px 3px 20px 10px #302a6f0f;
      border-radius: 16px;
      padding: 10px 4px 10px 0;
    }

    &__content {
      display: flex;
      flex-direction: column;
      width: 240px;
      max-height: var(--table-filter-dropdown-height, min(300px, 50vh));
      overflow-y: auto;
      scrollbar-width: auto;

      @-moz-document url-prefix() {
        scrollbar-width: thin;
        scrollbar-color: #825af9 #f6f6f6;
      }

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #f6f6f6;
        margin: 15px 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #825af9;
        border-radius: 12px;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 8px 10px 8px 20px;
      color: colors.$primary-black;
      cursor: pointer;
      transition: all 0.3s;

      &-icon {
        color: colors.$brand-color;
        overflow: auto;
        visibility: visible;
        opacity: 1;
      }

      &:hover {
        background: colors.$primary-background;
      }

      &[data-active='true'] {
        background: colors.$primary-lavender;
      }
    }
  }
</style>
