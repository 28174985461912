<script>

export default {
  name: 'TextCellField',
  props: {
    value: { type: [String, Number], default: '' },
    editable: { type: Boolean, default: false },
    containerClass: { type: String, default: '' },
    controlClass: { type: String, default: '' },
    manualModeChange: { type: Boolean, default: false },
    maxLength: { type: [String, Number], default: 64 },
  },
  emits: ['update:editable', 'input', 'submit', 'revert', 'click'],
  data: () => ({
    isEditMode: false,
    isSubmitted: false,
  }),
  watch: {
    editable: {
      handler(nextValue) {
        this.isEditMode = nextValue;
        nextValue && this.focusControl();
      },
      immediate: true,
    },
  },
  methods: {
    onContainerClick(e) {
      if (this.manualModeChange) return;
      this.isEditMode = true;
      this.$emit('update:editable', true);
      this.$emit('click', e);
      this.focusControl();
    },
    onInput(e) {
      this.$emit('input', e);
    },
    onBlur(e) {
      if (!this.isSubmitted) {
        this.$emit('submit', e);
      }
      this.isSubmitted = false;

      if (this.manualModeChange) return;
      this.isEditMode = false;
    },
    onPressEsc(e) {
      e.target.value = this.value;
      this.$emit('revert', e);
      this.$nextTick(() => document.activeElement?.blur());
    },
    focusControl() {
      this.$nextTick(() => this.$refs.control?.focus());
    },
    onKeydownEnter(e) {
      this.isSubmitted = true;
      e.target.blur();
      this.onBlur(e);
    },
  },
};
</script>

<template>
  <div
    :class="['text-cell-field', containerClass]"
    :data-manual-change-mode="manualModeChange"
    v-bind="$attrs"
    @click="onContainerClick"
  >
    <template v-if="!isEditMode">
      <slot />
    </template>

    <template v-else>
      <input
        ref="control"
        :class="['text-cell-field__control', controlClass]"
        :maxlength="maxLength"
        :value="value === 0 ? '' : value"
        type="number"
        @blur="onBlur"
        @input="onInput"
        @keydown.enter="onKeydownEnter"
        @keyup.esc="onPressEsc"
      >
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .text-cell-field {
    @include fonts.fontSize-big;
    @include fonts.raleway-medium;

    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    color: colors.$primary-black;

    &:not([data-manual-change-mode]) {
      cursor: text;
      padding: 14px 10px;
    }

    &__control {
      @include fonts.fontSize-big;
      @include fonts.raleway-medium;

      position: absolute;
      inset: 0;
      flex-grow: 1;
      min-height: 100%;
      height: 100%;
      width: 100%;
      border: none;
      outline: none;
      background: transparent;
      padding: 14px 10px;
    }
  }
</style>
