<script>
import { mapActions } from "vuex";
import { Icon } from "@iconify/vue2";

import DateRangePicker from "@/components/global/DateRangePicker.vue";

export default {
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    type: {
      type: String, // name, date
      required: true,
    },
  },
  data() {
    return {
      name: "",
      date: null,
    };
  },
  components: {
    Icon,
    DateRangePicker,
  },
  watch: {
    name(value) {
      this.$emit("on-change", value);
    },
    date(value) {
      this.setSearchCampaignDates(value);
    },
  },
  methods: {
    ...mapActions(["setSearchCampaignDates"]),
    onNameChange(e) {
      const value = e.target.value;
      this.name = value;
    },
    clearNameField() {
      this.name = "";
    },
    clearDateField() {
      this.date = null;
    },
  },
  emits: ["on-change"],
};
</script>

<template>
  <div class="fieldContainer">
    <div v-if="type === 'name'" class="field">
      <Icon icon="bx:search-alt-2" class="field-icon" height="25" width="25" />

      <input
        v-model="name"
        @input="onNameChange"
        type="search"
        class="field-input"
        :placeholder="placeholder"
      />

      <button
        v-if="name.length > 0"
        @click.prevent="clearNameField"
        class="btn"
      >
        <Icon class="btn-icon" icon="bi:x" height="25" width="25" />
      </button>
    </div>

    <div v-if="type === 'date'" class="field">
      <Icon icon="bi:calendar3" class="field-icon" height="20" width="20" />

      <DateRangePicker
        class="field-input"
        :placeholder="placeholder"
        :value.sync="date"
      />

      <button v-if="date !== null" @click.prevent="clearDateField" class="btn">
        <Icon class="btn-icon" icon="bi:x" height="25" width="25" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/colors.scss";
@import "@/style/breakpoints.scss";
@import "@/style/fonts.scss";

.fieldContainer {
  @include for-phone-only {
    width: 100%;
  }
}

.field {
  min-width: 300px;
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;

  &-icon {
    position: absolute;
    color: #bec2cd;
    margin-left: 19px;
  }

  &-input {
    @include raleway-regular;
    height: 48px;
    width: 100%;
    padding: 12px 16px 12px 60px;
    border: 1px solid transparent;
    border-radius: 16px;
    font-family: "Roboto";
    outline: none;

    &::placeholder {
      @include raleway-regular;
      color: #b1b6bc;
    }

    &:focus-visible {
      border: 1px solid $brand-color;
      box-shadow: none;
      outline: none;
    }

    &:focus {
      border: 1px solid $brand-color;
      box-shadow: none;
      outline: none;
    }
  }

  & .btn {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    display: flex;
    margin: 0px;
    padding: 0px;

    &:active {
      border: none;
    }

    &-icon {
      width: 20px;
      height: 20px;
      color: #bec2cd;
    }
  }
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
</style>
