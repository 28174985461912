import ApiService from "@/api/ApiService";
import { endpoints } from "@/api/endpoints";

const api = new ApiService();

export default {
  namespaced: true,
  state: () => ({
    campaignFormats: [],
  }),
  mutations: {
    setCampaignFormats(state, formats = []) {
      state.campaignFormats = formats;
    },
  },
  actions: {
    // managing technical description
    getTechnicalDescriptionList(_, params) {
      return api.get(`${endpoints.campaign.technical_description}${params.campaignId}`);
    },
    createTechnicalDescription(_, data) {
      return api.post(`${endpoints.campaign.technical_description}${data.campaignId}`, data.body);
    },
    deleteTechnicalDescription(_, data) {
      return api.delete(`${endpoints.campaign.technical_description}${data.campaignId}`, data.body);
    },
    publishTechnicalDescription(_, data) {
      return api.put(`${endpoints.campaign.technical_description}${data.campaignId}`, data.body);
    },
    editTechnicalDescription(_, data) {
      return api.put(`${endpoints.campaign.technical_description}${data.campaignId}`, data.body);
    },

    // managing offer status
    attachTechnicalDescription(_, data) {
      return api.put(`${endpoints.campaign.offer}`, data); // {offer_id: Number, description: Number}
    },
    sendTechnicalDescription(_, data) {
      return api.put(`${endpoints.campaign.offer}`, data); // {offer_id: Number, description: Number, status: 'sent_tz'}
    },
    approvePrice(_, data) {
      return api.put(`${endpoints.campaign.offer}`, data); // {offer_id: Number, status: 'approved_price'}
    },
    rejectPrice(_, data) {
      return api.put(`${endpoints.campaign.offer}`, data); // {offer_id: Number, status: 'rejected_price'}
    },
    approveDrafts(_, data) {
      return api.put(`${endpoints.campaign.offer}`, data); // {offer_id: Number, status: 'approved_draft'}
    },
    rejectDrafts(_, data) {
      return api.put(`${endpoints.campaign.offer}`, data); // {offer_id: Number, status: 'rejected_draft'}
    },
    approvePublish(_, data) {
      return api.put(`${endpoints.campaign.offer}`, data); // {offer_id: Number, status: 'approved_publish'}
    },
    rejectPublish(_, data) {
      return api.put(`${endpoints.campaign.offer}`, data); // {offer_id: Number, status: 'rejected_publish'}
    },

    // campaign creation
    async getCampaignFormats({ commit }) {
      const { data } = await api.get(endpoints.campaign.campaign_formats);
      commit("setCampaignFormats", data);
      return data;
    },
    createCampaign(_, data) {
      return api.post(endpoints.campaign.create_campaign, data);
    },
  },
  getters: {
    campaignFormats: (s) => s.campaignFormats
  }
};
