<script>
import { Icon } from "@iconify/vue2";
import { BaseBloggerImage } from "@/components/ui";

export default {
  name: "BaseSidebar",
  components: {
    BaseBloggerImage,
    Icon,
  },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    avatar: {
      type: String,
      required: false,
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    onHide(e) {
      if (e.trigger === "backdrop" || e.trigger === "esc") {
        this.$emit("change", false);
      }
    },
  },
  watch: {
    visible(value) {
      value
        ? document.body.setAttribute("data-scroll-lock", "true")
        : document.body.removeAttribute("data-scroll-lock");
    },
  },
  emits: ["change"],
};
</script>

<template>
  <b-sidebar
    :aria-expanded="visible ? 'true' : 'false'"
    :aria-controls="id"
    :id="id"
    :title="title"
    :hide-header="hideHeader"
    :hide-footer="hideFooter"
    backdrop
    shadow
    right
    :visible="visible"
    @change="$emit('change', $event)"
    @close="$emit('change', false)"
    @hide="onHide"
    no-header
  >
    <template #default="{ hide }">
      <div class="b-sidebar-header" id="sidebar-no-header-title">
        <button @click.prevent="hide" class="closeBtn">
          <Icon icon="bx-chevron-right" />
        </button>

        <div class="interlocutor">
          <BaseBloggerImage :size="40" :image="avatar" />
          <span class="interlocutor-name">{{ title }}</span>
        </div>
      </div>

      <slot></slot>
    </template>
  </b-sidebar>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";
@import "@/style/colors.scss";

:deep(.b-sidebar) {
  width: fit-content;
  min-width: 768px;
}

:deep(.b-sidebar-header) {
  background-color: $primary-white;
  width: 100%;
  height: 60px;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px;
  display: flex;
  align-items: center;
}

.closeBtn {
  width: 35px;
  height: 35px;
  border-radius: 50%;

  &:hover {
    background-color: $primary-background;
  }

  & svg {
    width: 100%;
    height: 100%;
    color: $secondary-light-grey;
  }
}

.interlocutor {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  padding-left: 20px;
  align-items: center;

  &-name {
    @include raleway-medium;
    font-size: 18px;
    line-height: 21px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 768px) {
  :deep(.b-sidebar) {
    width: 100vw;
    min-width: 100%;
  }
}
</style>