<script>
import TaskManagerModalBody from '@/components/campaign/TaskManagerModalBody.vue';
import BaseProvidedModal from '@/components/ui/BaseProvidedModal.vue';

export default {
  name: 'TaskManagerModal',
  components: {
    TaskManagerModalBody,
    BaseProvidedModal,
  },
  props: {
    modalId: { type: String, required: true },
    isButtonIconOnly: { type: Boolean, required: true },
  },
  emits: ['close'],
};
</script>

<template>
  <BaseProvidedModal :id="modalId" full size="xl" @hide="$emit('close', $event)">
    <TaskManagerModalBody :is-button-icon-only="isButtonIconOnly" />
  </BaseProvidedModal>
</template>

<style lang="scss" scoped></style>
