export function createStorage(key, defaultValue) {
  const getValue = () => {
    try {
      return JSON.parse(localStorage.getItem(key) ?? '') || defaultValue;
    } catch {
      return defaultValue;
    }
  };

  const setValue = (value) => {
    if (value === null || value === undefined || value === '') {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  };

  return { getValue, setValue };
}
