<script>
import { Icon } from '@iconify/vue2';
import { createNamespacedHelpers } from 'vuex';

import { TableCellPlaceholder } from '@/components/ui/tables';
import CellPopover from '@/components/ui/tables/fields/CellPopover.vue';
import { queryPluralText } from '@/functions/utils';

import InfluencerNotesCellContent from './InfluencerNotesCellContent.vue';

const agencyCabinetStore = createNamespacedHelpers('agencyCabinetModule');

export default {
  name: 'InfluencerNotesCell',
  components: { TableCellPlaceholder, CellPopover, InfluencerNotesCellContent, Icon },
  props: {
    table: { type: Object, default: () => ({}) },
    row: { type: Object, default: () => ({}) },
    cell: { type: Object, default: () => ({}) },
    columnDefs: { type: Object, default: () => ({}) },
  },
  data: () => ({
    innerValue: null,
  }),
  computed: {
    displayName() {
      return this.row.originalValue.full_name ?? '';
    },
    notes() {
      return this.row.originalValue.agent_notes ?? '';
    },
    offerId() {
      return this.row.originalValue.id ?? '';
    },
    notesLabel() {
      return queryPluralText(this.notes.length, [
        this.$gettext('заметка'),
        this.$gettext('заметки'),
        this.$gettext('заметок'),
      ]);
    },
  },
  methods: {
    ...agencyCabinetStore.mapActions({
      createNote: 'createNote',
      deleteNote: 'deleteNote',
    }),
    async onSubmit(value) {
      await this.createNote({ ...value, offer: this.offerId });
    },
    async onRemove(id) {
      const headerTitle = this.$gettext('Delete note');
      const headerDescription = this.$gettext(
        'Are you sure you want to delete the selected note? This action cannot be undone',
      );
      await this.deleteNote({ id: [id], textOption: { headerTitle, headerDescription } });
    },
  },
};
</script>

<template>
  <CellPopover>
    <template #trigger>
      <div class="notes-cell">
        <span v-if="notes.length" class="notes-cell__tag">
          <Icon icon="bx:note" width="20" />
          {{ notesLabel }}
        </span>
        <div>
          <TableCellPlaceholder position="left" variant="modal" />
        </div>
      </div>
    </template>
    <template #content="{ close }">
      <InfluencerNotesCellContent
        :influencer="displayName"
        :notes="notes"
        @submit="onSubmit"
        @close:note="close"
        @delete:note="onRemove"
      />
    </template>
  </CellPopover>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .notes-cell {
    display: flex;
    align-items: center;
    gap: 5px;
    overflow: hidden;
    text-overflow: ellipsis;

    &__tag {
      @include fonts.fontSize-normal;
      @include fonts.raleway-medium;
      border-radius: 8px;
      padding: 5px 10px;
      background-color: colors.$primary-background-dark;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      min-width: 72px;
      max-width: 100%;
    }
  }
</style>
