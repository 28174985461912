<script>
import TaskListModalBody from '@/components/campaign/TaskListModalBody.vue';
import BaseProvidedModal from '@/components/ui/BaseProvidedModal.vue';
import { modalService } from '@/services/modal.service';

export default {
  name: 'TaskListModal',
  components: {
    TaskListModalBody,
    BaseProvidedModal,
  },
  props: {
    modalId: { type: String, required: true },
    items: { type: Array, required: false, default: () => [] },
    taskId: { type: Number, required: false, default: null },
    multiply: { type: Boolean, required: false, default: false },
  },
  emits: ['close', 'update-state', 'on-empty-state', 'on-redirect'],
  data() {
    return {
      errorModalId: 'errorModal',
    };
  },
  methods: {
    onRedirect(e) {
      modalService().close(this.modalId);
      this.$emit('on-redirect', e);
    },
  },
};
</script>

<template>
  <BaseProvidedModal
    :id="modalId"
    :hide-header="true"
    full
    size="lg"
    @hide="$emit('close', $event)"
  >
    <template #default="{ payload }">
      <TaskListModalBody
        :bloggers="payload"
        :items="items"
        :task-id="taskId || payload?.description?.id"
        @update-state="$emit('update-state', $event)"
        @on-empty-state="$emit('on-empty-state', $event)"
        @on-redirect="onRedirect"
      />
    </template>
  </BaseProvidedModal>
</template>
