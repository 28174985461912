<script>
import { Icon } from '@iconify/vue2';

import colors from '@/style/colors';

const Status = Object.freeze({
  Completed: 'completed',
  Accepted: 'accepted',
  Rejected: 'rejected',
  Info: 'info',
  Default: 'default',
  Demo: 'demo',
  New: 'new',
  RejectedDraft: 'rejected_draft',
  RejectedPrice: 'rejected_price',
  RejectedPublish: 'rejected_publish',
  RejectedSpecification: 'rejected_tz',
  Deleted: 'deleted',
});

const StatusOptionMap = {
  [Status.Completed]: { className: 'green', canDisplayArrow: false },
  [Status.Accepted]: { className: 'green', canDisplayArrow: false },
  [Status.Rejected]: { className: 'red', canDisplayArrow: false },
  [Status.Default]: { className: 'blue', canDisplayArrow: true },
  [Status.Info]: { className: 'violet', canDisplayArrow: false },
  [Status.New]: { className: 'violet', canDisplayArrow: true },
  [Status.Demo]: { className: 'violet', canDisplayArrow: false },
  [Status.RejectedDraft]: { className: 'red', canDisplayArrow: true },
  [Status.RejectedPrice]: { className: 'red', canDisplayArrow: true },
  [Status.RejectedPublish]: { className: 'red', canDisplayArrow: true },
  [Status.RejectedSpecification]: { className: 'red', canDisplayArrow: true },
  [Status.Deleted]: { className: 'red', canDisplayArrow: true },
};

export default {
  components: { Icon },
  props: {
    status: { type: String, required: true },
    status_translate: { type: String, default: Status.Default },
    arrow: { type: Boolean, default: false },
    arrowDir: { type: String, validator: (v) => ['up', 'down'].includes(v), default: 'up' },
  },
  data: () => ({
    colors: colors,
  }),
  computed: {
    validatedStatus() {
      return Object.values(Status).includes(this.status) ? this.status : Status.Default;
    },
    statusOption() {
      return StatusOptionMap[this.validatedStatus];
    },
    isDisplayArrow() {
      return this.arrow && this.statusOption.canDisplayArrow;
    },
    arrowDirClassName() {
      return this.isDisplayArrow ? this.arrowDir : '';
    },
  },
};
</script>

<template>
  <div class="labelContainer" v-bind="$attrs">
    <span :class="['label', statusOption.className, arrowDirClassName]">
      <Icon
        v-if="arrow && statusOption.canDisplayArrow"
        :class="['icon', arrowDirClassName]"
        icon="bx:left-arrow-alt"
      />
      {{ status_translate }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
  @import '@/style/fonts.scss';
  @import '@/style/breakpoints.scss';

  .labelContainer {
    width: min-content;
    display: flex;
    align-items: center;
  }

  .label {
    @include raleway-medium;
    display: flex;
    align-items: center;
    gap: 4px;
    height: 22px;
    width: max-content;
    max-width: max-content;
    border-radius: 20px;
    font-size: var(--base-status-label-size, 10px);
    padding: 0 8px;
    border: 0;
    white-space: nowrap;
    vertical-align: middle;

    @include for-big-desktop-up {
      font-size: var(--base-status-label-size-lg, var(--base-status-label-size, 12px));
    }

    &.blue {
      background: #d7e5fc;
      color: #367bf2;

      &.up {
        background: #eff5fe;
        color: #68a0ff;
      }
    }

    &.green {
      background: #d6f4de;
      color: #34c759;
    }

    &.red {
      background: #fbd9e4;
      color: #ec407a;
    }

    &.violet {
      background: #f5f2ff;
      color: #825af9;
    }
  }

  .icon {
    width: 16px;
    height: 16px;

    &.up {
      transform: rotate(90deg);
    }

    &.down {
      transform: rotate(-90deg);
    }
  }
</style>
