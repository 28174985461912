<script>
import { Icon } from '@iconify/vue2';

import TableCellPlaceholder from '@/components/ui/tables/TableCellPlaceholder.vue';
import { modalService } from '@/services/modal.service';

export default {
  name: 'ModalTableCell',
  components: { Icon, TableCellPlaceholder },
  props: {
    table: { type: Object, default: () => ({}) },
    row: { type: Object, default: () => ({}) },
    cell: { type: Object, default: () => ({}) },
    columnDefs: { type: Object, default: () => ({}) },
  },
  computed: {
    currentColumn() {
      return this.cell.col?.key ?? '';
    },
    value() {
      return this.cell.value;
    },
    meta() {
      return this.columnDefs[this.currentColumn].meta || {};
    },
    config() {
      return {
        targetModal: this.meta.targetModal ?? '',
        icon: this.meta.icon ?? '',
        displayTextAccessor:
          typeof this.meta.displayTextAccessor === 'function'
            ? this.meta.displayTextAccessor
            : () => '',
      };
    },
    displayedText() {
      return this.config.displayTextAccessor(this.value);
    },
  },
  methods: {
    openModal() {
      const target = this.config.targetModal;
      target && modalService().open(target, this.row.originalValue);
    },
  },
};
</script>

<template>
  <div class="modal-table-cell" @click="openModal">
    <template v-if="displayedText">
      <div :title="displayedText" class="modal-table-cell__tag">
        <Icon v-if="config.icon" icon="bx:note" width="20" />
        {{ displayedText }}
      </div>
    </template>
    <template v-else>
      <TableCellPlaceholder position="left" variant="modal" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .modal-table-cell {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    padding: 14px 10px;
    cursor: pointer;
    color: colors.$primary-black;
    @include fonts.fontSize-big;
    @include fonts.raleway-medium;

    &__icon {
      color: colors.$brand-color;
      background-color: colors.$primary-lavender-dark;
      border-radius: 8px;
      cursor: pointer;
      padding: 5px;
      width: 30px;
      height: 30px;
    }

    &__tag {
      @include fonts.fontSize-normal;
      @include fonts.raleway-medium;
      border-radius: 8px;
      padding: 5px 10px;
      background-color: colors.$primary-background-dark;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }
</style>
