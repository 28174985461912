import { RouteName } from '@/values/routeName';
import { UserRole } from '@/values/user';

function createMeta(pageName = '') {
  return {
    title: ['Advy.ai - Agency dashboard', pageName].filter(Boolean).join(' - '),
    requiresAuth: true,
    isAgencyCabinet: true,
    roles: [UserRole.Admin],
  };
}

export default [
  {
    path: `/${RouteName.AgencyCabinetRoot}`,
    name: RouteName.AgencyCabinetCampaignList,
    component: () => import('../views/campaigns/index.vue'),
    meta: createMeta(''),
  },
  {
    path: `/${RouteName.AgencyCabinetRoot}/:id`,
    name: RouteName.AgencyCabinetCampaign,
    component: () => import('../views/campaign/index.vue'),
    redirect: { name: RouteName.AgencyCabinetGeneralDescription },
    meta: createMeta('Campaign'),
    props: true,
    children: [
      {
        path: 'description',
        name: RouteName.AgencyCabinetDescription,
        component: () => import('../views/campaign/description/index.vue'),
        meta: createMeta('Overview'),
        children: [
          {
            path: 'general',
            name: RouteName.AgencyCabinetGeneralDescription,
            component: () => import('../views/campaign/description/general.vue'),
            meta: createMeta('General overview'),
            props: true,
          },
          {
            path: 'bloggers',
            name: RouteName.AgencyCabinetBloggersDescription,
            component: () => import('../views/campaign/description/bloggers.vue'),
            meta: createMeta('Bloggers overview'),
            props: true,
          },
        ],
      },
      {
        path: 'bloggers',
        name: RouteName.AgencyCabinetBloggers,
        component: () => import('../views/campaign/agency-bloggers.vue'),
        meta: createMeta('Bloggers'),
        props: true,
      },
      {
        path: 'selection',
        name: RouteName.AgencyCabinetSelection,
        component: () => import('../views/campaign/selection.vue'),
        meta: createMeta('Selection'),
        props: true,
      },
    ],
  },
];
