<template>
  <b-radio
    :checked="value"
    :disabled="disabled"
    :value="raw"
    button-variant="secondary"
    class="base-radio"
    plain
    @input="$emit('input', $event)"
  >
    {{ label }}
  </b-radio>
</template>

<script>
export default {
  name: 'BaseRadio',
  props: {
    disabled: { default: null },
    value: { type: String, default: '' },
    raw: { type: String, default: '' },
    label: { type: String, default: '' },
  },
  emits: ['input'],
};
</script>

<style lang="scss" scoped>
@use '@/style/fonts';

.base-radio {
  @include fonts.raleway-regular;
  font-size: 16px;
  line-height: 25px;
  margin: 0;
  color: #27292c;
  transition: all 200ms;

  &.form-check {
    display: block;
    padding-left: 26px;
  }

  :deep(.form-check-input:checked) {
    background-color: #825af9;
    border-color: #825af9;
  }

  :deep(.form-check-input) {
    margin-left: -26px;
  }
}
</style>