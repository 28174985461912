<script>
import { Icon } from "@iconify/vue2";
import colors from "@/style/colors";

export default {
  name: "Loading",
  components: {
    Icon,
  },
  props: {
    type: {
      type: String, // dots, round
      required: false,
      default: "dots",
    },
    backdrop: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      colors: colors,
    };
  },
};
</script>

<template>
  <div :class="{ loadingContainer: backdrop }">
    <Icon
      v-if="type === 'dots'"
      icon="eos-icons:three-dots-loading"
      width="40px"
      :color="colors.primaryBlack"
    />
    <Icon
      v-if="type === 'round'"
      icon="eos-icons:bubble-loading"
      width="40px"
      :color="colors.primaryBlack"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/colors.scss";

.loadingContainer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1001;
  background-color: $primary-lavender;
  opacity: 0.7;
  overflow: hidden;
}
</style>
