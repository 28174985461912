<script>
import { Icon } from '@iconify/vue2';

import SettingsButton from '@/components/settings/SettingsButton.vue';
import BaseModal from '@/components/ui/BaseModal.vue';

export default {
  name: 'ModalError',
  components: {
    BaseModal,
    Icon,
    SettingsButton,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  emits: ['cancel', 'on-hide'],
};
</script>

<template>
  <BaseModal :modal-id="modalId" @on-hide="$emit('on-hide')">
    <div class="wrapper">
      <div class="content">
        <Icon class="icon" icon="eos-icons:rotating-gear" />

        <h3 class="subtitle">
          <translate>Что-то пошло не так</translate>
        </h3>
      </div>

      <div class="controls">
        <SettingsButton :title="$gettext('Отмена')" type="button" @click="$emit('cancel')" />
        <SettingsButton
          :title="$gettext('Повторить')"
          type="button"
          @click="$bvModal.hide(modalId)"
        />
      </div>
    </div>
  </BaseModal>
</template>

<style lang="scss" scoped>
  @import '@/style/fonts.scss';

  .controls {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;

    @media (max-width: 576px) {
      flex-direction: column;
      row-gap: 10px;
    }
  }

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 20px 20px 20px;

    & .content {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      text-align: center;

      & .subtitle {
        @include raleway-semibold;
        font-size: 18px;
        line-height: 27px;
        margin: 0px;
      }

      & .icon {
        height: 50px;
        width: 50px;
      }
    }
  }
</style>
