<script>
import { Icon } from '@iconify/vue2';
import isEqual from 'lodash-es/isEqual';
import { createNamespacedHelpers } from 'vuex';

import { TableCellPlaceholder, TextCellField } from '@/components/ui/tables';
import { toNumber, toNumberString } from '@/functions/normalize';
import { NetworkAssetMap } from '@/values/network';

import { ColumnKey, FormatCell } from '../campaignInfluencerTableColumn';
import { createTablePatchPayload } from '../tableHelpers';

const agencyCabinetStore = createNamespacedHelpers('agencyCabinetModule');

export default {
  name: 'InfluencerMetricsCell',
  components: { TableCellPlaceholder, TextCellField, Icon },
  props: {
    table: { type: Object, default: () => ({}) },
    row: { type: Object, default: () => ({}) },
    cell: { type: Object, default: () => ({}) },
    columnDefs: { type: Object, default: () => ({}) },
  },
  data: () => ({
    ColumnKey,
    NetworkAssetMap,
    innerValue: '',
  }),
  computed: {
    currentColumn() {
      return this.cell.col?.key ?? '';
    },
    meta() {
      return this.columnDefs[this.currentColumn].meta || {};
    },
    value() {
      return this.cell.value ?? '';
    },
    formattedValue() {
      switch (this.meta.format) {
        case FormatCell.Float:
          return toNumberString(this.innerValue || 0, '%');
        case FormatCell.Currency:
          return toNumberString(this.innerValue || 0, ` ${this.currency}`);
        default:
          return toNumberString(this.innerValue || 0);
      }
    },
    calculateResidue() {
      const paidCost = toNumber(this.row.originalValue.spend ?? 0);
      const totalCost = toNumber(this.row.originalValue.price ?? 0);

      if (paidCost >= totalCost) return '';
      const residue = totalCost - paidCost;
      return toNumberString(residue);
    },
    network() {
      return this.row.originalValue.influencer_network ?? '';
    },
    currency() {
      return this.row.originalValue.currency ?? '';
    },
  },
  watch: {
    value: {
      handler(value) {
        this.innerValue = value ?? '';
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...agencyCabinetStore.mapActions({ updateRow: 'updateRow' }),
    onRevert() {
      this.innerValue = this.value;
    },
    async onSubmit(e) {
      const inputValue = e.target.value.replace(/\s+/g, '');
      const prevValue = toNumber(this.innerValue);
      const nextValue = toNumber(inputValue);

      if (isEqual(prevValue, nextValue)) {
        return;
      }

      const payload = createTablePatchPayload(this.row.originalValue, {
        [this.currentColumn]: nextValue,
      });

      this.innerValue = nextValue;
      const isSubmitted = await this.updateRow(payload);
      !isSubmitted && this.onRevert();
    },
  },
};
</script>
<template>
  <TextCellField
    ref="cell"
    :value="innerValue"
    class="metric-cell"
    @revert="onRevert"
    @submit="onSubmit"
  >
    <div v-if="innerValue" class="metric-cell__content">
      <Icon
        v-if="currentColumn === ColumnKey.Followers && NetworkAssetMap[network]?.icon"
        :icon="NetworkAssetMap[network]?.icon"
        class="metric-cell__icon"
        width="15"
      />
      <div class="metric-cell__value">
        <span>
          {{ formattedValue }}
        </span>
        <span
          v-if="currentColumn === ColumnKey.PaidAmount && calculateResidue.length > 0"
          class="metric-cell__residue"
        >
          <translate>remaining</translate>
          <span>:</span>
          {{ calculateResidue }}
        </span>
      </div>
    </div>
    <TableCellPlaceholder v-else />
  </TextCellField>
</template>
<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .metric-cell {
    justify-content: end;

    &__content {
      display: flex;
      align-items: center;
      overflow: hidden;
    }

    &__icon {
      margin-right: 10px;
      color: colors.$brand-color;
      flex-shrink: 0;
    }

    &__value {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      overflow: hidden;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
      }
    }

    &__residue {
      @include fonts.fontSize-small;
      color: colors.$primary-grey-light;
    }
  }
</style>
