<script>
export default {
  name: 'TableFilterDropdownItem',
  props: {
    controlAlign: { type: String, default: 'left' },
  },
};
</script>

<template>
  <div :data-control-align="controlAlign" class="table-filter-dropdown-item" v-bind="$attrs">
    <span class="table-filter-dropdown-item__content"><slot /></span>
    <span class="table-filter-dropdown-item__control"><slot name="control" /></span>
  </div>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .table-filter-dropdown-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 10px 8px 20px;
    color: colors.$primary-black;
    transition: all 0.3s;

    &__content {
      @include fonts.raleway-medium;
      @include fonts.fontSize-big;

      display: flex;
      align-items: center;
      text-align: left;
      white-space: pre-wrap;
      line-clamp: 2;
      flex-grow: 1;
      min-height: 24px;
    }

    &[data-control-align='right'] {
      flex-direction: row-reverse;
    }

    &:hover {
      background: colors.$primary-background;
    }
  }
</style>
