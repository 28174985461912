export function calcTotalCost(price, commission) {
  if (price <= 0 || commission < 0 || commission > 100) return 0;
  const total = 100 * (price / (100 - commission));
  return Number(total.toFixed(2));
}

export function calcCommissionCost(price, totalPrice) {
  if (price <= 0 || totalPrice <= 0 || price >= totalPrice) return 0;
  const commission = 100 * (1 - price / totalPrice);
  return commission.toFixed(2);
}

export function calcReachFromSubscribers(actualReach, followersCount) {
  if (!actualReach || !followersCount) return;
  return ((actualReach / followersCount) * 100).toFixed(2);
}

export function calcReachFromRest(actualReach, reach_followers) {
  if (!reach_followers || !actualReach) return;
  return ((reach_followers / actualReach) * 100).toFixed(2);
}

export function calcSuspiciousPercent(audience_types) {
  const { suspicious = 0, mass_followers = 0 } = audience_types || {};

  if (!suspicious || !mass_followers) return;
  return (suspicious + mass_followers).toFixed(2);
}
