export const photoExtensions = new Set([
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.bmp',
  '.tiff',
  '.webp',
  '.avif',
]);

export const videoExtensions = new Set(['.mp4', '.mov', '.wmv', '.flv', '.avi', '.mkv', '.webm']);
