import { getLanguage } from '@/services/locale.service';

let lang = getLanguage();

let API_HOST = '';
if (process.env.VUE_APP_BASE_URL_API) {
  API_HOST = `${process.env.VUE_APP_BASE_URL_API}/${lang}`;
} else {
  API_HOST = `http://localhost:8000/${lang}`;
}

const TELEGRAM_LINK = 'https://t.me/IvanTrizonov';

const NETWORK_LIST = {
  instagram: {
    name: 'Instagram',
    icon: 'instagram.svg',
    link: 'https://instagram.com/',
  },
  tiktok: {
    name: 'TikTok',
    icon: 'tiktok.svg',
    link: 'https://tiktok.com/@',
  },
  telegram: {
    name: 'Telegram',
    icon: 'telegram.svg',
    link: 'https://t.me/',
  },
};

const LOCAL_STORAGE_KEYS = {
  filterShowBy: 'filterShowBy',
  filterViewType: 'filterViewType',
  bannersVisibilityState: 'bannersVisibilityState',
  influencersTableHidingState: 'table.campaign-influencers.hiding',
  influencersTablePaginationState: 'table.campaign-influencers.pagination',
  influencersTableStatusFilter: 'table.campaign-influencers.status-filter',
};

const INITIAL_BANNERS_VISIBILITY_STATE = {
  isBannerBestCampaignVisible: true,
  isBannerTopBloggersVisible: true,
  isBannerOtherCampaignsVisible: true,
};

export {
  API_HOST,
  INITIAL_BANNERS_VISIBILITY_STATE,
  LOCAL_STORAGE_KEYS,
  NETWORK_LIST,
  TELEGRAM_LINK,
};
