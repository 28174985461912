<script>
import { Icon } from "@iconify/vue2";

export default {
  name: "BaseViewButton",
  components: { Icon },
  props: {
    colorMode: {
      validator: (value) => {
        return ["primary", "secondary", "tertiary", "quaternary"].includes(value);
      },
      required: false,
      default: "primary",
    },
    text: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    buttonText() {
      return this.text ? this.text : this.$gettext("View");
    },
    buttonType() {
      return this.icon ? "square-button" : "view-button";
    },
    buttonClasses() {
      return { [this.buttonType]: true, [this.colorMode]: true };
    },
  },
  emits: ["on-click"],
};
</script>

<template>
  <button :class="buttonClasses" @click="$emit('on-click')">
    <Icon v-if="icon" :icon="icon" />
    <span v-else>{{ buttonText }}</span>
  </button>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";
@import "@/style/colors.scss";
@import "@/style/breakpoints.scss";

.view-button {
  @include raleway-bold;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  border-radius: 16px;
  font-size: 12px;
  white-space: nowrap;
  border: none;

  @include for-big-desktop-up {
    height: 44px;
    justify-content: center;
  }

  &:disabled {
    opacity: 0.3;
  }
}

.square-button {
  @include raleway-bold;
  height: 40px;
  min-height: 40px;
  width: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-size: 12px;
  border: none;

  @include for-big-desktop-up {
    height: 44px;
    width: 44px;
    justify-content: center;
  }

  &:disabled {
    opacity: 0.3;
  }

  & svg {
    width: 16px;
    height: 16px;
  }
}

.primary {
  background-color: $brand-color;
  color: $primary-white;
  transition: 0.2s;

  &:focus,
  &:active,
  &:focus-visible,
  &:active:focus,
  &.active:focus {
    box-shadow: none;
    outline: none;
  }

  &:not(:disabled):active,
  &:not(:disabled):hover {
    background-color: $brand-color-dark;
  }
}

.secondary {
  background-color: $primary-lavender;
  color: $brand-color;
  transition: 0.2s;

  &:focus,
  &:active,
  &:focus-visible,
  &:active:focus,
  &.active:focus {
    box-shadow: none;
  }

  &:not(:disabled):active,
  &:not(:disabled):hover {
    background: $primary-lavender-dark;
  }
}

.tertiary {
  background-color: $primary-background;
  color: $primary-black;
  transition: 0.2s;

  &:focus,
  &:active,
  &:focus-visible,
  &:active:focus,
  &.active:focus {
    box-shadow: none;
  }

  &:not(:disabled):active,
  &:not(:disabled):hover {
    background: $primary-background-dark;
  }
}

.quaternary {
  background-color: $primary-black;
  color: $primary-white;
  transition: 0.2s;

  &:focus,
  &:active,
  &:focus-visible,
  &:active:focus,
  &.active:focus {
    box-shadow: none;
  }

  &:not(:disabled):active,
  &:not(:disabled):hover {
    background-color: $secondary-grey;
  }
}
</style>