import { inputOnlyNumber } from '@/functions/normalize';
<template>
  <div class="base-input-wrapper">
    <label v-if="label" :for="value" class="base-input-label">{{ label }}</label>
    <input
      :id="value"
      :disabled="disabled"
      :type="type"
      :value="value"
      class="form-control base-input"
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"
      @keydown="type === 'number' ? numberOnly : null"
      @keydown.enter.prevent
    >
  </div>
</template>

<script>
import { inputOnlyNumber } from '@/functions/normalize';

export default {
  name: 'BaseInput',
  props: {
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    value: { type: String, default: '' },
    type: { type: String, default: 'text' },
  },
  emits: ['input'],
  methods: {
    numberOnly(e) {
      inputOnlyNumber(e);
    },
  },
};
</script>

<style lang="scss" scoped>
  @use '@/style/fonts';

  .base-input-wrapper {
    display: flex;
    flex-direction: column;
  }

  .base-input-label {
    @include fonts.raleway-regular;
    margin-bottom: 5px;
    color: #27292c;
    font-size: 14px;
  }

  .base-input {
    @include fonts.raleway-regular;
    font-size: 16px;
    line-height: 25px;
    padding: 10px 15px;
    margin: 0;
    height: 44px;
    color: #27292c;
    border-radius: 12px;
    border: 1px solid #cfd4d9;
    transition: all 200ms;

    &::placeholder {
      color: #aeb3b9;
    }

    &:focus-visible {
      border-color: #825af9;
    }
  }
</style>
