<script>
export default {
  name: 'MultipleEditingModalSection',
  props: {
    title: { type: String, required: true },
  },
};
</script>

<template>
  <section class="m-editing-modal-section">
    <div class="m-editing-modal-section__header">
      {{ title }}
      <slot name="header-content" />
    </div>

    <slot />
  </section>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;
  @use '@/style/breakpoints.scss' as breakpoints;

  .m-editing-modal-section {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &__header {
      @include fonts.raleway-bold;
      @include fonts.fontSize-big;

      display: flex;
      flex-direction: column;
      gap: 5px;

      @include breakpoints.for-big-desktop-up {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
</style>
