import store from "@/store";
import { buildGetParams } from "@/functions/buildGetParams";

export async function processTopUp(accountId, returnUrl, amount) {
  try {
    const paymentProviders = await store.dispatch("financeModule/getPaymentProviders");

    if (paymentProviders.length === 0) {
      throw new Error("NO PAYMENT PROVIDERS AVAILABLE");
    }

    const providerKey = paymentProviders.data[0].key;
    const providerId = paymentProviders.data[0].id;

    const paymentParams = buildGetParams({ account_id: accountId, provider_key: providerKey });
    let paymentMethods = await store.dispatch("financeModule/getPaymentMethods", paymentParams);

    if (paymentMethods.data.length === 0) {
      await store.dispatch("financeModule/addPaymentMethod", {
        account_id: accountId,
        provider: providerId,
        default: false,
      });

      paymentMethods = await store.dispatch("financeModule/getPaymentMethods", paymentParams);
    }

    const depositPayload = {
      returnUrl,
      body: {
        method_id: paymentMethods.data[0].id,
        amount,
      },
    };

    const depositResponse = await store.dispatch("financeModule/callDeposit", depositPayload);
    return depositResponse.data.link;
  } catch (err) {
    console.error(err);
    throw new Error("Payment processing error");
  }
}
