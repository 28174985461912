<script>
import { Icon } from "@iconify/vue2";

import BaseCircleButton from "@/components/ui/BaseCircleButton.vue";

export default {
  name: "BaseSettingsButton",
  components: {
    Icon,
    BaseCircleButton,
  },
  props: {
    size: {
      type: Number,
      required: false,
      default: 48,
    },
  },
  emits: ["on-click"],
};
</script>

<template>
  <div :style="{ width: `${size}px`, height: `${size}px` }">
    <BaseCircleButton @click="$emit('on-click')">
      <Icon class="icon" icon="bx:list-ul" />
    </BaseCircleButton>
  </div>
</template>


<style scoped lang="scss">
@import "@/style/colors.scss";

.icon {
  width: 25px;
  height: 25px;
  color: $primary-grey;
}
</style>