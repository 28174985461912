<script>
import { Icon } from "@iconify/vue2";
import * as uuid from "uuid";

export default {
  name: "BaseInfoIcon",
  components: {
    Icon,
  },
  props: {
    tooltipText: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    id: "",
  }),
  created() {
    this.id = uuid.v4();
  },
};
</script>

<template>
  <div class="infoIconContainer">
    <Icon :id="id" class="icon" icon="bx:bxs-info-circle" />

    <b-tooltip :target="id" triggers="hover">
      {{ tooltipText }}
    </b-tooltip>
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/colors.scss";
@import "@/style/tooltip.scss";

.infoIconContainer {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  & .icon {
    width: 100%;
    height: 100%;
    color: $primary-grey;
    opacity: 0.5;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: $primary-grey;
    }
  }
}
</style>
