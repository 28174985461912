<script>

export default {
  name: 'ImageTableCell',
  props: {
    table: { type: Object, default: () => ({}) },
    row: { type: Object, default: () => ({}) },
    cell: { type: Object, default: () => ({}) },
    columnDefs: { type: Object, default: () => ({}) },
  },
  computed: {
    value() {
      return this.cell.value ?? null;
    },
  },
};
</script>

<template>
  <div class="image-table-cell">
    <img :src="value" alt="icon" class="image-table-cell__image">
  </div>
</template>

<style lang="scss" scoped>
  .image-table-cell {
    display: flex;
    align-items: center;
    justify-content: center;

    &__image {
      width: 24px;
      height: 24px;
    }
  }
</style>
