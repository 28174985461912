<script>
export default {
  name: "BaseLogo",
  props: {
    isCollapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<template>
  <div>
    <img
      v-if="isCollapsed"
      src="@/assets/logo-collapsed.svg"
      alt="Advy.ai Logo"
      class="logo-collapsed"
    />
    <img v-else src="@/assets/logo.svg" alt="Advy.ai Logo" class="logo" />
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/breakpoints.scss";

.logo {
  height: 32px;
  width: 121px;

  @include for-big-desktop-up {
    height: 38px;
    width: 147px;
  }

  @include for-phone-only {
    height: 28px;
    width: 105px;
  }

  &-collapsed {
    width: 32px;
  }
}
</style>