<script>
import BaseButton from '@/components/ui/BaseButton.vue';
import { RouteName } from '@/values/routeName';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseButton,
  },
  methods: {
    redirectToBloggersSelection() {
      this.$router.push({
        name: RouteName.Selection,
      });
    },
  },
  emits: ['on-click'],
};
</script>

<template>
  <div class="content-wrapper">
    <h2 class="title">{{ title }}</h2>

    <span v-if="description" class="text-secondary description">
      {{ description }}
    </span>

    <BaseButton
      :rounded="true"
      :title="buttonText"
      @click="$emit('on-click')"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";

.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 16px;
  background-color: #fff;
  padding: 50px;
  color: #27292c;

  @media (max-width: 480px) {
    height: 100%;
    width: 100%;
    padding: 60px 30px;
  }
}

.title {
  @include title;
  text-align: center;
}

.description {
  width: 400px;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    width: 100%;
  }
}
</style>