<script>
import { Icon } from '@iconify/vue2';

import BaseProvidedModal from '@/components/ui/BaseProvidedModal.vue';
import BaseSolidButton from '@/components/ui/BaseSolidButton.vue';
import { modalService } from '@/services/modal.service';
import { ComponentState } from '@/values/componentState';

export default {
  name: 'FailureModal',
  components: { BaseSolidButton, BaseProvidedModal, Icon },
  props: {
    modalId: { type: String, required: true },
  },
  data: () => ({
    ComponentState,
    state: ComponentState.isError,
  }),
  computed: {
    modalPayload() {
      return modalService().getState(this.modalId)?.payload ?? {};
    },
    modalState() {
      return {
        errorMessage: this.modalPayload.errorMessage ?? this.$gettext('Something went wrong'),
        errorStatus: this.modalPayload.errorStatus ?? null,
        sendRequest: this.modalPayload.sendRequest ?? (() => Promise.resolve()),
        rejectRequest: this.modalPayload.rejectRequest ?? (() => Promise.reject()),
      };
    },
    isFailureInClient() {
      return /^4[0-9]{2}$/.test(this.modalState.errorStatus);
    },
    errorMessage() {
      return this.modalState.errorMessage
        ? this.$gettext(this.modalState.errorMessage)
        : this.$gettext(
          'An error occurred while editing, and we couldn’t save your changes. Please try again or cancel to revert to the previous state.',
        );
    },
  },
  methods: {
    async retryRequest() {
      try {
        this.state = ComponentState.isLoading;
        await this.modalState.sendRequest();
      } finally {
        this.state = ComponentState.isError;
      }
    },
    async cancelChanges() {
      await this.modalState.rejectRequest();
    },
  },
};
</script>

<template>
  <BaseProvidedModal
    :id="modalId"
    :closable="false"
    :title="$gettext('Something went wrong')"
    size="md"
    title-align="center"
  >
    <div class="failure-modal">
      <div class="failure-modal__description">
        {{ errorMessage }}
      </div>

      <div class="failure-modal__footer">
        <template v-if="state === ComponentState.isLoading">
          <BaseSolidButton size="md" style="pointer-events: none" variant="primary">
            <Icon icon="svg-spinners:3-dots-bounce" style="margin-top: 2px" />
            <translate>Processing your request</translate>
          </BaseSolidButton>
        </template>

        <template v-if="state === ComponentState.isError">
          <BaseSolidButton
            :style="{ 'min-width': '185px' }"
            :text="$gettext('Cancel changes')"
            size="md"
            variant="primary-light"
            @click="cancelChanges"
          />
        </template>

        <template v-if="(state === ComponentState.isError) & !isFailureInClient">
          <BaseSolidButton
            :style="{ 'min-width': '185px' }"
            :text="$gettext('Retry request')"
            size="md"
            variant="primary"
            @click="retryRequest"
          />
        </template>
      </div>
    </div>
  </BaseProvidedModal>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;
  @use '@/style/breakpoints.scss' as breakpoints;

  .failure-modal {
    display: flex;
    flex-direction: column;
    gap: 40px;
    min-height: 100%;
    width: 100%;

    &__description {
      @include fonts.raleway-regular;
      @include fonts.fontSize-big;

      width: 100%;
      color: colors.$primary-black;
      margin: 0;
      text-align: center;
      line-height: 20px;

      @include breakpoints.for-phone-only {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
  }
</style>
