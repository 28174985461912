<script>
import { Icon } from '@iconify/vue2';

export default {
  name: 'TableCellPlaceholder',
  components: { Icon },
  props: {
    gutters: { type: Boolean, default: false },
    position: { type: String, default: 'right' },
    variant: {
      type: String,
      validator: (value) => ['input', 'select', 'modal', 'content'].includes(value),
      default: 'input',
    },
  },
  computed: {
    icon() {
      switch (this.variant) {
        case 'modal':
        case 'content':
          return 'bx:plus';
        default:
          return 'tabler:circle-dashed-plus';
      }
    },
  },
};
</script>

<template>
  <div
    :data-align="position"
    :data-gutters="gutters"
    :data-variant="variant"
    class="table-cell-placeholder"
  >
    <Icon :data-variant="variant" :icon="icon" class="table-cell-placeholder__icon" width="20" />
  </div>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .table-cell-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    color: colors.$primary-grey-light;

    &[data-gutters='true'] {
      padding: 14px 10px;
    }

    &[data-variant='input'] {
      cursor: text;
    }

    &[data-variant='select'],
    &[data-variant='modal'] {
      cursor: pointer;
    }

    &[data-align='left'] {
      justify-content: start;
    }

    &__icon {
      width: 20px;
      height: 20px;

      &[data-variant='modal'] {
        padding: 7px;
        border-radius: 8px;
        background-color: colors.$primary-lavender-dark;
        width: 34px;
        height: 34px;
        color: colors.$brand-color;
      }
    }
  }
</style>
