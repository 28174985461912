<script>
import { mapActions, mapState } from "vuex";
import { Icon } from "@iconify/vue2";

import BaseCircleButton from "@/components/ui/BaseCircleButton.vue";

import userDefaultImage from "@/assets/svgs/user-default-icon.svg";

export default {
  name: "TheUser",
  components: {
    Icon,
    BaseCircleButton,
  },
  created() {
    this.getUsersAccount().then(() => {
      this.getUsersProfilePage(this.accountId);
    });
  },
  methods: {
    ...mapActions(["logoutClient", "getUsersAccount", "getUsersProfilePage"]),
    logOut() {
      this.logoutClient();
      this.$router.push({ name: "start" });
    },
  },
  computed: {
    ...mapState(["user", "accountId"]),
    icon() {
      return userDefaultImage;
    },
  },
  emits: ["on-click"],
};
</script>

<template>
  <div class="user">
    <div class="user-caption">
      <p v-if="user" class="email">{{ user.user_email }}</p>
      <a @click.prevent="logOut" href="#" class="logout">
        <translate>LOGOUT</translate>
      </a>
    </div>

    <BaseCircleButton @click="$emit('on-click')">
      <img class="user-image" :src="icon" alt="" />
    </BaseCircleButton>
  </div>
</template>


<style lang="scss" scoped>
@import "@/style/fonts.scss";
@import "@/style/colors.scss";

.user {
  height: 50px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 15px;

  @media (max-width: 767px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  &-caption {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    & .email {
      @include raleway-regular;
      font-size: 14px;
      line-height: 24px;
      margin: 0px;
    }

    & .logout {
      @include raleway-medium;
      font-size: 12px;
      line-height: 18px;
      color: $brand-color;
      margin: 0px;
      transition: none;

      :hover {
        color: $brand-color;
        border-bottom: 1px solid $brand-color;
      }
    }
  }

  &-image {
    width: 100%;
    height: 100%;
  }
}
</style>
