<script>
import { Icon } from "@iconify/vue2";
import colors from "@/style/colors";

import BaseCircleButton from "@/components/ui/BaseCircleButton.vue";

export default {
  name: "BaseChatButton",
  components: {
    Icon,
    BaseCircleButton,
  },
  props: {
    size: {
      type: Number,
      required: false,
      default: 40,
    },
    iconSize: {
      type: Number,
      required: false,
      default: 16,
    },
    unreadMsgNumber: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return { colors: colors };
  },
  computed: {
    formattedUnreadNumber() {
      return this.unreadMsgNumber >= 100 ? "99+" : this.unreadMsgNumber;
    },
  },
  emits: ["on-click"],
};
</script>

<template>
  <div>
    <BaseCircleButton
      :customStyle="{
        height: `${size}px`,
        width: `${size}px`,
        backgroundColor: colors.primaryBackground,
        position: 'relative',
        overflow: 'visible',
      }"
      @click="$emit('on-click')"
    >
      <div v-if="unreadMsgNumber" class="indicator">
        {{ formattedUnreadNumber }}
      </div>

      <Icon
        icon="bxs-message-rounded"
        :color="colors.primaryBlack"
        :width="iconSize"
        :height="iconSize"
      />
    </BaseCircleButton>
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/colors.scss";
@import "@/style/fonts.scss";

.indicator {
  @include raleway-regular;
  font-size: 10px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -7px;
  right: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $secondary-red;
  color: $primary-white;
}
</style>