export default {
  updateAccountsList(state, accountsList) {
    state.accountsList = accountsList;
    state.accountId = accountsList[0].id;
  },
  updateUser(state, data) {
    state.user = data[0];
    state.userAccountsList = data[3];
  },
  updateCardList(state, cardList) {
    state.cardList = cardList;
  },
  updateCampaignList(state, campaigns) {
    state.campaignList = campaigns?.results ?? [];
    state.campaignTotalCount = campaigns?.count ?? 0;
  },
  updateCampaignStatuses(state, statuses) {
    state.campaignStatuses = statuses ?? [];
  },
  updateCampaign(state, campaign) {
    state.campaign = campaign;
  },
  updateCampaignSettings(state, campaignSettings) {
    state.campaignSettings = campaignSettings;
  },
  updateCampaignDescription(state, campaignDescription) {
    state.campaignDescription = campaignDescription;
  },
  updateCampaignInfluencers(state, data) {
    state.campaignInfluencers = data.results;
    state.campaignInfluencersTotal = data.count;
  },
  updateCampaignResults(state, campaignResults) {
    state.campaignResults = campaignResults.results;
    state.campaignResultsTotal = campaignResults.count;
  },
  updateCampaignBarters(state, campaignBarters) {
    state.campaignBarters = campaignBarters.results;
  },
  updateCampaignSelections(state, campaignSelections) {
    state.campaignSelections = campaignSelections[0];
    state.campaignSelectionsScore = campaignSelections[1];
  },
  updateTheme(state, theme) {
    state.theme = theme;
  },
  updateInterests(state, interests) {
    state.interests = interests;
  },
  updateGeos(state, geos) {
    state.geos = geos;
  },
  updateSearchCampaignName(state, name) {
    state.searchCampaignName = name;
  },
  updateSearchCampaignDates(state, dates) {
    state.searchCampaignDates = dates;
  },
  updateSearchInfluencerName(state, name) {
    state.searchInfluencerName = name;
  },
  updateFavouriteBloggers(state, favouriteBloggers) {
    state.favouriteBloggers = favouriteBloggers;
  },
  updateBannersVisibility(state, bannersVisibilityState) {
    state.bannersVisibilityState = bannersVisibilityState;
  },
};
