<script>
export default {
  name: "BaseModal",
  props: {
    modalId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideFooter: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    onHide(e) {
      if (e.trigger === "backdrop" || e.trigger === "esc") {
        this.$emit("on-hide");
      }
    },
  },
  emits: ["on-hide"],
};
</script>

<template>
  <b-modal
    :id="modalId"
    :title="title"
    :size="size"
    :hide-header="hideHeader"
    :hide-footer="hideFooter"
    centered
    scrollable
    @hide="onHide"
    @close="$emit('on-hide')"
  >
    <slot></slot>

    <template #modal-footer>
      <slot name="footer"></slot>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";
@import "@/style/colors.scss";

:deep(.modal > .modal-dialog > .modal-content) {
  border-radius: 16px;
  background-color: $primary-background;
}

:deep(.modal > .modal-dialog > .modal-content > .modal-header) {
  min-height: 50px;
  border-bottom: none;
  justify-content: center;

  & .modal-title {
    @include raleway-semibold;
    text-align: center;
    font-size: 28px;
    line-height: 30px;
  }

  & .close {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

:deep(.modal > .modal-dialog > .modal-content > .modal-header > .close) {
  height: 50px;
  width: 50px;
  padding: 0;
  color: $secondary-light-grey;
}

:deep(.modal > .modal-dialog > .modal-content > .modal-body) {
  padding-top: 0px;
}

:deep(.modal > .modal-dialog > .modal-content > .modal-footer) {
  padding: 20px;
}
</style>