<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, String],
      required: false,
    },
    valueSecondary: {
      type: Number,
      required: false,
    },
  },
};
</script>

<template>
  <div class="headingWrapper">
    <div class="title-container">
      <h1 class="title-text">{{ title }}{{ value }}</h1>
      <span v-if="valueSecondary" class="title-value">{{ valueSecondary }}</span>
      <slot name="after-title"></slot>
    </div>

    <div class="slot-controls">
      <slot name="controls"></slot>
    </div>

    <div class="slot-content">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";
@import "@/style/colors.scss";
@import "@/style/breakpoints.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.headingWrapper {
  width: 100%;
  min-height: 48px;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 20px;
  row-gap: 10px;

  @include for-phone-only {
    grid-template-columns: 1fr 1fr;
  }

  & .title {
    &-container {
      min-width: max-content;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-text {
      @include title;
      @include raleway-bold;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-value {
      @include raleway-regular;
      font-size: 16px;
      color: $primary-grey;
    }
  }

  .slot-content {
    width: 100%;
    grid-column: 1 / 3;
  }

  .slot-controls {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
