<script>
import { Icon } from '@iconify/vue2';

import BaseCircleButton from '@/components/ui/BaseCircleButton.vue';

export default {
  components: { Icon, BaseCircleButton },
  props: {
    color: { type: String, required: false },
  },
  emits: ['on-click'],
};
</script>

<template>
  <BaseCircleButton @click="$emit('on-click')">
    <Icon
      :color="color"
      class="icon"
      icon="bxs:file-export"
    />
  </BaseCircleButton>
</template>

<style lang="scss" scoped>
.icon {
  width: 25px;
  height: 25px;
}
</style>
