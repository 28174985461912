<script>
import 'vueperslides/dist/vueperslides.css';

import { Icon } from '@iconify/vue2';
import { VueperSlide, VueperSlides } from 'vueperslides';

import colors from '@/style/colors';
import TableContentLink from '@/widgets/CampaignInfluencersTable/components/TableContentLink.vue';

export default {
  name: 'CampaignContentSection',
  components: {
    TableContentLink,
    Icon,
    VueperSlides,
    VueperSlide,
  },
  props: {
    type: { type: String, required: true },
    items: { type: Array, required: false, default: () => [] },
    title: { type: String, required: true },
    refName: { type: String, required: false, default: '' },
    link: { type: String, required: false, default: '' },
    admin: { type: Boolean, default: false },
  },
  emits: ['add-content', 'remove-content'],
  data() {
    return {
      colors: colors,
    };
  },
  methods: {
    showNext() {
      this.$refs[this.refName].next();
    },
    showPrevious() {
      this.$refs[this.refName].previous();
    },
    addContent() {
      this.$refs.fileInput.click();
    },
    addAttachment(event) {
      const file = event.target.files[0];
      if (file) {
        this.$emit('add-content', file);
      }
    },
    updateLink(link) {
      this.$emit('add-content', link);
    },
    getText(title) {
      switch (title) {
        case 'Скриншоты':
          return this.$gettext('скриншотов');
        case 'Файлы':
          return this.$gettext('файлов');
        case 'Черновики':
          return this.$gettext('черновиков');
        case 'Репорты':
          return this.$gettext('репортов');
        default:
          return title.toLowerCase();
      }
    },
    removeSlide(event, item) {
      event.preventDefault();
      this.$emit('remove-content', item);
    },
  },
};
</script>

<template>
  <div>
    <div v-if="type === 'link'" class="section">
      <div class="section-heading">
        <span class="section-subtitle">
          {{ title }}
        </span>
      </div>

      <TableContentLink v-if="admin" :link="link" class="section-link" @update:link="updateLink" />

      <a v-if="!admin" :href="link" class="link" target="_blank">
        <Icon :color="colors.primaryGrey" class="link-icon" icon="bx-link-alt" />
        <span class="link-text">{{ link }}</span>
        <Icon :color="colors.primaryBlue" class="link-icon" icon="bx-link-external" />
      </a>
    </div>

    <div v-if="type === 'content'" class="section">
      <div v-if="admin || items.length" class="section-heading">
        <div class="section-heading">
          <span class="section-subtitle">{{ title }}</span>
          <span v-if="items.length" class="section-amount">{{ items.length }}</span>
          <button @click="addContent">
            <Icon v-if="admin" class="section-add" icon="bx:plus" />
          </button>
        </div>

        <div v-if="items.length" class="navigation">
          <button class="navigation-btn" @click="showPrevious">
            <Icon color="black" icon="bx-chevron-left" width="20" />
          </button>
          <button class="navigation-btn" @click="showNext">
            <Icon color="black" icon="bx-chevron-right" width="20" />
          </button>
        </div>
      </div>

      <vueper-slides
        v-if="items.length"
        :ref="refName"
        :arrows="false"
        :breakpoints="{
          570: { visibleSlides: 4, slideMultiple: 2 },
          640: { visibleSlides: 3, slideMultiple: 2 },
          768: { visibleSlides: 3, slideMultiple: 2 },
          991: { visibleSlides: 4, slideMultiple: 3 },
          1200: { visibleSlides: 6, slideMultiple: 4 },
          2560: { visibleSlides: 8, slideMultiple: 4 },
        }"
        :bullets="false"
        :dragging-distance="200"
        :gap="1"
        :touchable="false"
        :visible-slides="8.5"
        class="no-shadow"
        slide-multiple
      >
        <vueper-slide
          v-for="item in items"
          :key="item.id"
          :image="item.attachment"
          :link="item.attachment"
          :open-in-new="true"
        >
          <template v-if="item.attachment" #content>
            <div class="custom-slide">
              <img
                v-if="item.type === 'video'"
                :src="require('@/assets/play.png')"
                alt="video"
                class="custom-slide-icon"
              >
              <button v-if="admin" class="close-button" @click="removeSlide($event, item.id)">
                <Icon icon="bx:bx-x" />
              </button>
            </div>
          </template>
        </vueper-slide>
      </vueper-slides>

      <div v-if="admin & !items.length" class="section no-content">
        <div class="no-content__header">
          <Icon icon="ci:note-search" width="24" />
          <span>
            <translate>No</translate>
            {{ getText(title) }}
          </span>
        </div>
        <span class="no-content__description">
          <translate>Add</translate>
          {{ title.toLowerCase() }}
        </span>
      </div>
    </div>
    <input
      id="formFile"
      ref="fileInput"
      accept="image/*,video/*"
      style="display: none"
      type="file"
      @input="addAttachment"
    >
  </div>
</template>

<style lang="scss" scoped>
  @import '@/style/colors.scss';
  @import '@/style/fonts.scss';
  @import '@/style/breakpoints.scss';

  .section {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }

    &-subtitle {
      @include raleway-bold;
      font-size: 16px;
      line-height: 24px;

      @include for-big-desktop-up {
        font-size: 18px;
        line-height: 28px;
      }
    }

    &-amount {
      @include raleway-regular;
      @include fontSize-normal;
      color: $primary-grey;
    }

    &-add {
      color: $primary-lavender-dark;
      background-color: $brand-color;
      border-radius: 8px;
      cursor: pointer;
      padding: 5px;
      width: 28px;
      height: 28px;
      margin-left: 10px;
    }
  }

  .link {
    @include raleway-semibold;
    margin: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    height: 60px;
    display: grid;
    align-items: center;
    grid-template-columns: 24px 1fr 24px;
    padding: 0 20px;
    gap: 10px;
    background-color: $primary-white;
    border-radius: 8px;
    overflow: hidden;

    &:hover {
      color: $primary-blue;
    }

    &-icon {
      height: 24px;
      width: 24px;
      min-width: 24px;
    }

    &-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $primary-blue;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .navigation {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      border-radius: 8px;
      background-color: $primary-white;
      padding: 0;
    }
  }

  .vueperslides {
    height: 160px;
    min-height: 160px;
  }

  :deep(.vueperslides__track) {
    overflow: visible;
  }

  :deep(.vueperslides__inner) {
    height: 160px;
  }

  :deep(.vueperslides__parallax-wrapper) {
    height: 100%;
    padding-bottom: 0 !important;
    overflow: visible;
  }

  .vueperslide {
    width: 100px;
    height: 160px;
    border-radius: 8px;
    background-color: $primary-black;

    :hover .close-button {
      display: block;
    }
  }

  .custom-slide {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-icon {
      width: 36px;
      height: 36px;
    }
  }

  .close-button {
    display: none;
    width: 30px;
    height: 30px;
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    background-color: $primary-white;
    color: $brand-color;
    padding: 5px;
    border-radius: 30px;
  }

  .no-content {
    height: 100px;
    justify-content: center;
    align-items: center;

    &__header {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      color: $primary-black;
      @include raleway-bold;
      @include fontSize-big;
    }

    &__description {
      color: #636d79;
      @include fontSize-normal;
    }
  }
</style>
