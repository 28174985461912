<script>
import { BaseNotification } from "@/components/ui";
import BalanceWidget from "@/components/finance/BalanceWidget.vue";
import TheUser from "@/components/menu/TheUser.vue";

export default {
  name: "BaseHeader",
  components: {
    BalanceWidget,
    BaseNotification,
    TheUser,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", () => (this.windowWidth = window.innerWidth));
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => (this.windowWidth = window.innerWidth));
  },
  computed: {
    isElementVisible() {
      return this.windowWidth > 767;
    },
    isBalanceVisible() {
      return this.windowWidth >= 600;
    },
  },
  methods: {
    handleUserClick() {
      if (this.$route.path !== "/settings") {
        this.$router.push({ name: "settings" });
      }
    },
  },
};
</script>

<template>
  <div class="headerWrapper">
    <slot></slot>

    <div class="controls">
      <BalanceWidget v-if="isBalanceVisible" />

      <div v-if="isElementVisible" class="controls-right">
        <BaseNotification :size="50" />
        <TheUser @on-click="handleUserClick" />
      </div>
    </div>
  </div>
</template>
  
<style lang="scss" scoped>
@import "@/style/breakpoints.scss";

.headerWrapper {
  width: 100%;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 992px) {
    flex-direction: column-reverse;
  }

  @include for-phone-only {
    display: none;
    min-height: 0px;
  }

  & .controls {
    min-height: 50px;
    width: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 20px;

    &-right {
      display: flex;
      gap: 20px;
    }

    @include for-phone-only {
      min-height: 0px;
      display: none;
    }
  }
}
</style>
  