<script>
import { Icon } from '@iconify/vue2';

import colors from '@/style/colors';

export default {
  name: 'TableContentLink',
  components: { Icon },
  props: {
    link: { type: String, default: '' },
  },
  data: () => ({
    inputValue: '',
    isBlurring: false,
    isEdit: false,
  }),
  computed: {
    colors() {
      return colors;
    },
  },
  watch: {
    link: {
      immediate: true,
      handler(newValue) {
        this.inputValue = newValue;
      },
    },
  },
  methods: {
    onFocus() {
      this.isEdit = true;
    },
    onBlur() {
      if (this.isBlurring) return;
      this.isBlurring = true;

      if (this.inputValue !== this.link) {
        this.$emit('update:link', this.inputValue);
      }
      this.$refs.link.blur();
      this.isEdit = false;
      this.isBlurring = false;
    },
    clearLink() {
      this.inputValue = '';
    },
    toResource() {
      window.open(this.inputValue, '_blank');
    },
  },
};
</script>

<template>
  <div class="content-link">
    <div class="content-link__container">
      <Icon :color="colors.primaryGrey" class="content-link__icon" icon="bx-link-alt" />
      <input
        ref="link"
        v-model="inputValue"
        :placeholder="$gettext('Publication link')"
        class="content-link__input"
        type="text"
        @blur="onBlur"
        @focus="onFocus"
        @keyup.enter="onBlur"
      >
    </div>

    <button v-show="!isEdit && inputValue" @mousedown="toResource">
      <Icon :color="colors.primaryBlue" class="content-link__icon" icon="bx-link-external" />
    </button>

    <button v-show="isEdit && inputValue" @mousedown="clearLink">
      <Icon :color="colors.secondaryLightGrey" class="content-link__icon" icon="bx-x" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .content-link {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: colors.$primary-white;
    padding: 0 20px;
    height: 60px;

    &__container {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
    }

    &__input {
      border: none;
      outline: none;
      box-shadow: none;
      height: 100%;
      width: 100%;
      @include fonts.raleway-semibold;
      @include fonts.fontSize-normal;
      color: colors.$primary-blue;

      &::placeholder {
        color: colors.$secondary-white-grey;
        @include fonts.raleway-semibold;
        @include fonts.fontSize-normal;
      }

      &:focus {
        color: colors.$primary-black;
      }
    }

    &__icon {
      height: 24px;
      width: 24px;
      min-width: 24px;
    }
  }
</style>
