<script>
export default {
  name: "BaseFormGroup",
  props: {
    asRow: { type: Boolean, default: false },
    align: {
      validator: (value) => ["left", "right", "center"].includes(value),
      default: "left",
    },
  },
};
</script>

<template>
  <div
    :class="[
      'base-form-group',
      asRow && 'base-form-group--as-row',
      `base-form-group--${align}`
    ]"
    v-bind="$attrs"
  >
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.base-form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  &.base-form-group--as-row {
    flex-direction: row;

    &.base-form-group--left {
      justify-content: flex-start;
    }

    &.base-form-group--right {
      justify-content: flex-end;
    }

    &.base-form-group--center {
      justify-content: center;
    }
  }
}
</style>