import ApiService from '@/api/ApiService'

const api = new ApiService()

const actions = {
  getShop(context, params) {
    return api.get(`/api/shop/public/${params.shopId}`)
  },
}

export default {
  namespaced: true,
  actions,
}
