import ApiService from '@/api/ApiService'

const api = new ApiService()

const actions = {
  getInfluencerPage(context, params) {
    return api.get(`/api/adv/influencer_page/${params.influencerId}/${params.influencerAccount}`)
  },
}

export default {
  namespaced: true,
  actions,
}
