import axios from 'axios';
import Vue from 'vue';
import store from '@/store';
import { API_HOST } from '@/config';
import { ErrorCode } from '@/values/errors';

export default class ApiService {
  static ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'accessToken';
  static REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'refreshToken';
  static USER_ID_LOCAL_STORAGE_KEY = 'userId';

  constructor(options = { onCreated: () => null }) {
    this.client = axios.create();
    this.refreshRequest = null;

    this.client.interceptors.request.use(
      (config) => {
        if (!localStorage.getItem(ApiService.ACCESS_TOKEN_LOCAL_STORAGE_KEY)) {
          return config;
        }

        const newConfig = {
          headers: {},
          ...config,
        };

        newConfig.headers.Authorization = `Bearer ${localStorage.getItem(ApiService.ACCESS_TOKEN_LOCAL_STORAGE_KEY)}`;

        return newConfig;
      },
      (error) => Promise.reject(error),
    );

    this.client.interceptors.response.use(
      (response) => response,
      async (error) => {
        // handle payment error
        if (error.response.status === 402) {
          Vue.notify({
            group: 'app',
            type: 'error',
            data: { code: ErrorCode.PaymentError },
          });
        }

        if (
          !localStorage.getItem(ApiService.REFRESH_TOKEN_LOCAL_STORAGE_KEY) ||
          error.response.status !== 401 ||
          error.config.retry
        ) {
          return Promise.reject(error);
        }

        if (error.response.status === 401) {
          localStorage.removeItem(ApiService.ACCESS_TOKEN_LOCAL_STORAGE_KEY);
          localStorage.removeItem(ApiService.REFRESH_TOKEN_LOCAL_STORAGE_KEY);
          localStorage.removeItem('userId');
          window.location.reload();
        }

        // here we check if the refreshToken request has already been sent
        if (!this.refreshRequest) {
          this.refreshRequest = this.client.post('/api/users/login_refresh', {
            refreshToken: localStorage.getItem(ApiService.REFRESH_TOKEN_LOCAL_STORAGE_KEY),
          });
        }

        const { token, user } = await this.refreshRequest.data;

        localStorage.setItem(ApiService.ACCESS_TOKEN_LOCAL_STORAGE_KEY, token.accessToken);
        localStorage.setItem(ApiService.REFRESH_TOKEN_LOCAL_STORAGE_KEY, token.refreshToken);
        localStorage.setItem(ApiService.USER_ID_LOCAL_STORAGE_KEY, user);

        const newRequest = {
          ...error.config,
          retry: true,
        };

        return this.client(newRequest);
      },
    );

    options.onCreated(this.client);
  }

  get authToken() {
    return store.getters.accessToken;
  }

  get(url, params = {}, binary = false) {
    const config = {
      baseURL: API_HOST,
      method: 'GET',
      url: url,
      headers: {},
      params: params,
    };
    if (binary) config['responseType'] = 'arraybuffer';

    return this.client.get(url, config);
  }

  post(url, params = {}, headers = {}) {
    const config = {
      baseURL: API_HOST,
      headers: headers,
    };

    return this.client.post(url, params, config);
  }

  postFormData(url, formData = {}) {
    const config = {
      baseURL: API_HOST,
      method: 'POST',
      url: `/api/adv/campaign_files/`,
      data: formData,
    };

    return this.client.post(url, formData, config);
  }

  put(url, params = {}) {
    const config = {
      baseURL: API_HOST,
      method: 'PUT',
    };

    return this.client.put(url, params, config);
  }

  patch(url, params = {}, headers = {}) {
    const config = {
      baseURL: API_HOST,
      method: 'PATCH',
      headers,
    };

    return this.client.patch(url, params, config);
  }

  delete(url, params = {}) {
    const config = {
      baseURL: API_HOST,
      data: params,
    };

    return this.client.delete(url, config);
  }
}
