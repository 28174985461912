<script>
import { autoUpdate, computePosition, flip, offset, shift } from '@floating-ui/dom';

export default {
  name: 'CellPopover',
  emits: ['close', 'open'],
  data: () => ({
    isOpen: false,
    cleanUpFn: null,
  }),
  watch: {
    isOpen: {
      handler(isOpen) {
        document.removeEventListener('focusin', this.onClickOutside.bind(this));
        this.updateFloating();
        this.$emit(isOpen ? 'open' : 'close');

        if (isOpen) {
          this.$nextTick(() => {
            this.$refs.positioner?.focus();
            document.addEventListener('focusin', this.onClickOutside.bind(this));
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    document.addEventListener('click', this.onClickOutside.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClickOutside.bind(this));
  },
  methods: {
    updateFloating() {
      this.$nextTick(() => {
        this.cleanUpFn && this.cleanUpFn();
        const targetRef = this.$refs.root;
        const floatingRef = this.$refs.positioner;

        if (!targetRef || !floatingRef) return;

        this.cleanUpFn = autoUpdate(targetRef, floatingRef, () => {
          computePosition(targetRef, floatingRef, {
            placement: 'bottom',
            middleware: [shift({ crossAxis: true }), offset(10), flip()],
          }).then(({ x, y }) => {
            Object.assign(floatingRef.style, { left: `${x}px`, top: `${y}px` });
          });
        });
      });
    },
    onTriggerClick() {
      // Safari don't emit focus, when user click a button
      this.$refs.trigger?.focus();
    },
    onClickOutside({ target }) {
      if (!this.isOpen) return;
      if (!this.$refs.positioner?.contains(target) && !this.$refs.root?.contains(target)) {
        this.close();
      }
    },
    toggleOpen(nextValue = !this.isOpen) {
      setTimeout(() => (this.isOpen = nextValue), 0);
    },
    close() {
      this.$refs.positioner?.blur();
      this.isOpen = false;
    },
  },
};
</script>

<template>
  <div ref="root" :data-expanded="isOpen" class="cell-popover" v-bind="$attrs">
    <button
      ref="trigger"
      :aria-expanded="isOpen"
      :data-expanded="isOpen"
      class="cell-popover__trigger"
      @click="onTriggerClick"
      @focus="toggleOpen(true)"
    >
      <slot name="trigger" />
    </button>

    <template v-if="isOpen">
      <portal to="positioner">
        <div
          ref="positioner"
          aria-modal="true"
          class="cell-popover__positioner"
          role="dialog"
          tabindex="0"
        >
          <div class="cell-popover__content">
            <slot :close="close" name="content" />
          </div>
        </div>
      </portal>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .cell-popover {
    @include fonts.fontSize-big;
    @include fonts.raleway-medium;

    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    color: colors.$primary-black;

    &__trigger {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      flex-grow: 1;
      padding: 14px 10px;
    }

    &__positioner {
      position: fixed;
      z-index: 99;
      top: 170%;
      left: -20px;
      isolation: isolate;
      height: min-content;
      width: min-content;
      background: colors.$primary-white;
      box-shadow: 1px 3px 20px 10px #302a6f0f;
      border-radius: 16px;
      padding: 10px 4px 10px 0;

      &:focus {
        outline: none;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      min-width: 240px;
      width: min-content;
      height: min-content;
      max-height: min(640px, 50vh);
      overflow-y: auto;
      scrollbar-width: auto;

      @-moz-document url-prefix() {
        scrollbar-width: thin;
        scrollbar-color: #825af9 #f6f6f6;
      }

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #f6f6f6;
        margin: 15px 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #825af9;
        border-radius: 12px;
      }
    }
  }
</style>
