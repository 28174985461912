<script>
import { createNamespacedHelpers, mapState } from "vuex";
import { Decimal } from "decimal.js";
import { API_HOST } from "@/config";
import { processTopUp } from "@/services/paymentService.js";

import { BaseModal, BaseViewButton, Loading } from "@/components/ui";
import { ModalError } from "@/components/menu";

const { mapActions } = createNamespacedHelpers("financeModule");

export default {
  name: "PaymentModal",
  components: {
    BaseModal,
    BaseViewButton,
    Loading,
    ModalError,
  },
  data() {
    return {
      state: "default",
      screen: 1,
      currentUrl: null,
      topUpAmount: null,
      errorModalId: "errorModal",
    };
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    offerId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    $route: {
      handler() {
        const currentPath = this.$route.path;
        this.currentUrl = `${API_HOST}${currentPath}`;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState({ accountId: "accountId" }),
  },
  methods: {
    ...mapActions({ getBalance: "getBalance" }),
    async handleAcceptBtnClick() {
      try {
        await this.getBalance().then((response) => {
          const data = response.data;
          const availableBalance = new Decimal(data.available_balance_advertiser);

          if (this.price > availableBalance) {
            this.screen = 2;
            this.topUpAmount = Math.abs(availableBalance.minus(this.price)).toFixed(2);
            return;
          }

          this.$emit("onEnoughFunds", { offerId: this.offerId });
        });
      } catch (err) {
        this.showErrorModal();
        console.log(err);
      }
    },
    async handleTopUpBtnClick() {
      this.state = "isLoading";

      try {
        window.location.href = await processTopUp(this.accountId, this.currentUrl, this.topUpAmount);
      } catch (err) {
        this.state = "isError";
        console.error(err);
        this.showErrorModal();
      }
    },
    showErrorModal() {
      this.$bvModal.show(this.errorModalId);
    },
  },
  emits: ["onEnoughFunds"],
};
</script>

<template>
  <BaseModal :hideHeader="false" :modalId="modalId" @on-hide="screen = 1">
    <Loading v-if="state === 'isLoading'" :backdrop="true" type="round" />
    <ModalError :modalId="errorModalId" @cancel="$bvModal.hide(errorModalId)" />

    <div class="wrapper">
      <h2 class="title">
        <translate>Accept and pay</translate>
      </h2>

      <div v-if="screen === 1" class="content">
        <div class="text">
          <translate>
            You are about to accept influencer’s date and price terms and pay for campaign. You'll be charged
          </translate>
          <span class="price">$ {{ price | formatFloat }} </span>
          <translate>from your balance</translate>
        </div>

        <BaseViewButton
          :text="$gettext('Accept and pay')"
          style="width: min-content"
          @on-click="handleAcceptBtnClick"
        />
      </div>

      <div v-if="screen === 2" class="content">
        <div class="text">
          <translate>
            You don’t have enough money on your balance for influencer’s date and price terms. Top up your balance to
            start campaign.
          </translate>
        </div>

        <div class="controls">
          <div class="inputContainer">
            <span class="inputContainer-currency">$</span>
            <input v-model="topUpAmount" class="inputContainer-input" type="number" />
          </div>

          <BaseViewButton
            :text="$gettext('Top up balance and pay')"
            style="width: 100%"
            @on-click="handleTopUpBtnClick"
          />
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";
@import "@/style/colors.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 30px 30px 30px;

  @include for-phone-only {
    gap: 10px;
  }

  & .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 40px;
    color: $primary-black;

    @include for-phone-only {
      gap: 30px;
    }
  }

  & .controls {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
  }
}

.inputContainer {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 20px;
  border-radius: 12px;

  color: $primary-black;
  border: 1px solid $secondary-white-grey;
  text-align: end;

  &-currency {
    @include raleway-medium;
    font-size: 12px;
  }

  &-input {
    @include raleway-regular;
    height: 100%;
    width: 100%;
    font-size: 16px;
    text-align: end;
    border: none;
    direction: ltr;
    background-color: transparent;
    padding-right: 20px;

    &:focus-visible {
      outline: none;
    }
  }
}

.title {
  @include raleway-bold;
  margin: 0;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  @include for-phone-only {
    font-size: 18px;
    line-height: 21px;
  }
}

.text {
  @include raleway-regular;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  @include for-phone-only {
    font-size: 12px;
    line-height: 20px;
  }
}

.price {
  @include raleway-semibold;
  white-space: nowrap;
}
</style>
      