<script>
import BaseProvidedModal from '@/components/ui/BaseProvidedModal.vue';
import BaseSolidButton from '@/components/ui/BaseSolidButton.vue';
import { modalService } from '@/services/modal.service';
import { ComponentState } from '@/values/componentState';

export default {
  name: 'OutdatedModal',
  components: {
    BaseSolidButton,
    BaseProvidedModal,
  },
  props: {
    modalId: { type: String, required: true },
  },
  data: () => ({
    ComponentState,
    state: ComponentState.isError,
  }),
  computed: {
    modalPayload() {
      return modalService().getState(this.modalId)?.payload ?? {};
    },
    modalState() {
      return {
        refreshData: this.modalPayload.refreshData ?? (() => Promise.resolve()),
      };
    },
  },
  methods: {
    async refreshData() {
      try {
        await this.modalState.refreshData();
      } catch {
        this.state = ComponentState.isError;
      }
    },
  },
};
</script>

<template>
  <BaseProvidedModal
    :id="modalId"
    :closable="false"
    :title="$gettext('Data is outdated for some offers.')"
    size="md"
    title-align="center"
  >
    <div class="outdated-modal">
      <translate class="outdated-modal__description">
        The data you are viewing is outdated. Please refresh to see the latest information.
      </translate>

      <footer v-if="state === ComponentState.isError" class="outdated-modal__footer">
        <BaseSolidButton
          :style="{ 'min-width': '185px' }"
          :text="$gettext('Refresh')"
          size="md"
          variant="primary"
          @click="refreshData"
        />
      </footer>
    </div>
  </BaseProvidedModal>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;
  @use '@/style/breakpoints.scss' as breakpoints;

  .outdated-modal {
    display: flex;
    flex-direction: column;
    gap: 40px;
    min-height: 100%;
    width: 100%;

    &__description {
      @include fonts.raleway-regular;
      @include fonts.fontSize-big;

      width: 100%;
      color: colors.$primary-black;
      margin: 0;
      text-align: center;
      line-height: 20px;

      @include breakpoints.for-phone-only {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
  }
</style>
