<script>
import moment from 'moment';
import { createNamespacedHelpers } from 'vuex';

import { DateCellField } from '@/components/ui/tables';

import { createTablePatchPayload } from '../tableHelpers';

const agencyCabinetStore = createNamespacedHelpers('agencyCabinetModule');

export default {
  name: 'InfluencerDateCell',
  components: { DateCellField },
  props: {
    table: { type: Object, default: () => ({}) },
    row: { type: Object, default: () => ({}) },
    cell: { type: Object, default: () => ({}) },
    columnDefs: { type: Object, default: () => ({}) },
  },
  data: () => ({
    innerValue: '',
  }),
  computed: {
    currentColumn() {
      return this.cell.col?.key ?? '';
    },
    value() {
      return this.cell.value ?? null;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.innerValue = value ? moment(value).toDate() : '';
      },
      immediate: true,
    },
  },
  methods: {
    ...agencyCabinetStore.mapActions({ updateRow: 'updateRow' }),
    async onSubmit(nextValue) {
      const prevFormattedValue = moment(this.innerValue, 'YYYY-MM-DD');
      const nextFormattedValue = moment(nextValue, 'YYYY-MM-DD');

      if (prevFormattedValue.isSame(nextFormattedValue)) {
        return;
      }

      // TODO: onRevert
      const payload = createTablePatchPayload(this.row.originalValue, {
        [this.currentColumn]: nextFormattedValue.toISOString(true),
      });

      this.innerValue = nextValue;
      const isSubmitted = await this.updateRow(payload);
      !isSubmitted && this.onRevert();
    },
  },
};
</script>

<template>
  <DateCellField :value="innerValue" @submit="onSubmit" />
</template>
