<script>
import { Icon } from '@iconify/vue2';

export default {
  name: 'TableFilterDropdown',
  components: { Icon },
  props: {
    title: { type: String, required: true, default: '' },
    icon: { type: String, default: '' },
  },
  data: () => ({
    isOpen: false,
  }),
  created() {
    document.addEventListener('click', this.onClickOutside.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClickOutside.bind(this));
  },
  methods: {
    onClickOutside({ target }) {
      if (this.isOpen && !this.$refs.root?.contains(target)) {
        this.toggleOpen(false);
      }
    },
    toggleOpen(nextValue = !this.isOpen) {
      setTimeout(() => (this.isOpen = nextValue), 0);
    },
  },
};
</script>

<template>
  <div ref="root" class="table-filter-dropdown" v-bind="$attrs">
    <button
      :aria-expanded="isOpen"
      :data-active="isOpen"
      aria-haspopup="true"
      class="table-filter-dropdown__trigger"
      type="button"
      @click="toggleOpen()"
    >
      <Icon
        v-if="icon"
        :icon="icon"
        aria-hidden="true"
        height="20"
        width="20"
      />
      <span>{{ title }}</span>
      <Icon
        :style="{ transform: isOpen ? 'rotate(180deg)' : '' }"
        aria-hidden="true"
        height="20"
        icon="bx:chevron-down"
        width="20"
      />
    </button>

    <template v-if="isOpen">
      <div aria-modal="true" class="table-filter-dropdown__positioner" role="dialog">
        <div class="table-filter-dropdown__content">
          <slot />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .table-filter-dropdown {
    position: relative;
    outline: none;

    &__trigger {
      @include fonts.raleway-medium;
      @include fonts.fontSize-big;
      position: relative;
      display: flex;
      align-items: center;
      gap: 5px;
      transition: all 0.3s;
      z-index: 0;
      white-space: nowrap;
      outline: none;

      &:focus {
        outline: none;
      }

      &::before {
        content: '';
        position: absolute;
        inset: -12px -10px;
        border-radius: 12px;
        background: colors.$primary-white;
        border: 1px solid transparent;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: all 0.6s;
      }

      &[data-active='true']:before,
      &:focus::before,
      &:hover::before,
      &:active::before {
        opacity: 1;
        visibility: visible;
      }

      &[data-active='true']:before,
      &:active::before {
        border: 1px solid colors.$brand-color;
      }
    }

    &__positioner {
      position: absolute;
      z-index: 99;
      top: 170%;
      left: -20px;
      isolation: isolate;
      height: min-content;
      width: min-content;
      background: colors.$primary-white;
      box-shadow: 1px 3px 20px 10px #302a6f0f;
      border-radius: 16px;
      padding: 10px 4px 10px 0;
    }

    &__content {
      width: var(--table-filter-dropdown-width, 240px);
      max-height: var(--table-filter-dropdown-height, 400px);
      overflow-y: auto;
      scrollbar-width: auto;

      @-moz-document url-prefix() {
        scrollbar-width: thin;
        scrollbar-color: #825af9 #f6f6f6;
      }

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #f6f6f6;
        margin: 15px 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #825af9;
        border-radius: 12px;
      }
    }
  }
</style>
