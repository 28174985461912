<script>
import { Icon } from "@iconify/vue2";

export default {
  name: "SettingsButton",
  components: { Icon },
  props: {
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
};
</script>

<template>
  <b-button variant="outline-dark" class="btn" @click="$emit('click')">
    <Icon v-if="icon" :icon="icon" />
    <span>{{ title }}</span>
  </b-button>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";

.btn {
  @include raleway-semibold;
  min-width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px 20px;
  border-radius: 16px;
  border: 1px solid rgba(39, 41, 44, 1);
  font-size: 13px;
  line-height: 24px;
  white-space: nowrap;

  &:focus {
    box-shadow: none;
  }

  &:focus-visible {
    outline: none;
  }
  &:hover {
    background-color: rgba(39, 41, 44, 1);
    color: #ffffff;
  }
}
</style>