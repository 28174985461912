<script>
import MobileMenu from '@/components/menu/MobileMenu.vue';
import BaseLogo from '@/components/ui/BaseLogo.vue';
import BaseNotification from '@/components/ui/BaseNotification.vue';
import store from '@/store';
import { RouteName } from '@/values/routeName';

export default {
  name: 'MobileHeader',
  components: {
    BaseNotification,
    MobileMenu,
    BaseLogo,
  },
  data() {
    return {
      mobileMenuModalId: 'mobileMenuModal',
    };
  },
  computed: {
    nextRoute() {
      return store.getters.isCurrentUserAdmin
        ? RouteName.AgencyCabinetCampaignList
        : RouteName.Campaigns;
    },
  },
};
</script>

<template>
  <div class="header">
    <div class="logo">
      <router-link :to="nextRoute" class="">
        <BaseLogo />
      </router-link>
    </div>

    <div class="controls">
      <BaseNotification :size="50" />

      <button class="hamburger" @click="$bvModal.show(mobileMenuModalId)">
        <img alt="hamburger" src="@/assets/svgs/hamburger.svg">
      </button>
    </div>

    <MobileMenu :modal-id="mobileMenuModalId" />
  </div>
</template>

<style lang="scss" scoped>
  @import '@/style/colors.scss';

  .header {
    display: none;

    @media (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 20px;
      position: sticky;
      top: 0;
      z-index: 4;
      background-color: $primary-background;
    }

    & .logo {
      &-image {
        width: 100px;
      }
    }

    & .controls {
      display: flex;
      align-items: center;
      column-gap: 15px;

      & .hamburger {
        padding: 0px;
        border: none;
        overflow: hidden;
      }
    }
  }
</style>
