import ApiService from "@/api/ApiService";
import { endpoints } from "@/api/endpoints";

const api = new ApiService();

const actions = {
  getBalance() {
    return api.get(endpoints.finance.account_balance);
  },
  getPaymentProviders() {
    return api.get(endpoints.finance.payment_providers);
  },
  getPaymentMethods(_, params) {
    return api.get(`${endpoints.finance.payment_methods}?${params}`);
  },
  addPaymentMethod(_, payload) {
    return api.post(endpoints.finance.payment_methods, payload);
  },
  callDeposit(_, payload) {
    return api.post(`${endpoints.finance.deposit}?success_url=${payload.returnUrl}`, payload.body);
  },
};

export default {
  namespaced: true,
  actions,
};
