<script>
import 'vueperslides/dist/vueperslides.css';

import { createNamespacedHelpers } from 'vuex';

import { CampaignContentSection } from '@/components/campaign';
import ModalError from '@/components/menu/ModalError';
import { Loading } from '@/components/ui';
import BaseProvidedModal from '@/components/ui/BaseProvidedModal.vue';
import colors from '@/style/colors';

const { mapActions } = createNamespacedHelpers('chatModule');
export default {
  name: 'TableContentModal',
  components: { BaseProvidedModal, CampaignContentSection, Loading, ModalError },
  props: {
    modalId: { type: String, required: true },
    drafts: { type: Array, required: true, default: () => [] },
    screenshots: { type: Array, required: true, default: () => [] },
    reports: { type: Array, required: true, default: () => [] },
    link: { type: String, required: false, default: '' },
  },
  emits: ['on-hide', 'update:link', 'add:content', 'remove:content'],
  data: () => ({
    state: 'isLoading', // isLoading, isError, isSuccess, isEmpty
    errorModalId: 'errorModal',
    colors: colors,
    attachments: [],
  }),
  created() {
    this.state = 'isSuccess';
  },
  methods: {
    ...mapActions([
      'createMessagesListener',
      'removeMessagesListener',
      'fetchPrevMessages',
      'sendMessage',
      'uploadAttachmentFile',
    ]),
    handleAddContent(content, type) {
      if (typeof content === 'string') {
        this.$emit('update:link', content);
      } else if (typeof content === 'object') {
        this.$emit('add:content', { content, type });
      }
    },
    handleRemoveContent(content, type) {
      this.$emit('remove:content', { content, type });
    },
  },
};
</script>

<template>
  <BaseProvidedModal
    :id="modalId"
    :content-styles="{ height: '746px' }"
    :title="$gettext('Content')"
    size="xl"
    @hide="$emit('on-hide')"
  >
    <ModalError :modal-id="errorModalId" @cancel="$bvModal.hide(errorModalId)" />

    <div v-if="state === 'isLoading'" class="table-content__loading-container">
      <div class="loading">
        <Loading type="round" />
      </div>
    </div>

    <div v-if="state === 'isSuccess'" class="table-content__wrapper">
      <div class="table-content__description">
        <translate>Drafts and screenshots from the blogger</translate>
      </div>

      <CampaignContentSection
        :items="drafts"
        :title="$gettext('Drafts')"
        admin
        ref-name="draftsRef"
        type="content"
        @add-content="(content) => handleAddContent(content, 'drafts')"
        @remove-content="(content) => handleRemoveContent(content, 'drafts')"
      />

      <CampaignContentSection
        :items="screenshots"
        :title="$gettext('Screenshots')"
        admin
        ref-name="screenshotsRef"
        type="content"
        @add-content="(content) => handleAddContent(content, 'screenshots')"
        @remove-content="(content) => handleRemoveContent(content, 'screenshots')"
      />

      <CampaignContentSection
        :link="link"
        :title="$gettext('Publication link')"
        admin
        type="link"
        @add-content="handleAddContent"
      />

      <CampaignContentSection
        :items="reports"
        :title="$gettext('Reports')"
        admin
        ref-name="reportsRef"
        type="content"
        @add-content="(content) => handleAddContent(content, 'reports')"
        @remove-content="(content) => handleRemoveContent(content, 'reports')"
      />
    </div>
  </BaseProvidedModal>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;
  @use '@/style/breakpoints.scss' as breakpoints;

  .table-content {
    &__loading-container {
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      background-color: colors.$primary-background;
      opacity: 0.7;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 30px;

      @include breakpoints.for-phone-only {
        padding: 0;
      }
    }

    &__description {
      @include fonts.raleway-regular;
      font-size: 14px;
      color: colors.$primary-grey;
      margin: 0;

      @include breakpoints.for-phone-only {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
</style>
