const RouteName = {
  Start: 'start',
  PrivacyPolicy: 'privacy-policy',
  ServiceTerms: 'service-terms',
  Register: 'register',
  ResetPassword: 'reset-password',

  InfluencerPublic: 'influencer-public',

  Init: 'init',
  Shop: 'shop',
  Settings: 'settings',
  Favourites: 'favourites',

  Campaign: 'campaign',
  Campaigns: 'campaigns',
  Selection: 'selection',
  Bloggers: 'bloggers',
  Description: 'description',
  GeneralDescription: 'general-description',
  BloggersDescription: 'bloggers-description',

  // NEW Route names

  // CampaignRoot: 'campaign',
  // CampaignList: 'campaigns',
  // CampaignDescription: 'campaign-description',
  // CampaignGeneralDescription: 'campaign-general-description',
  // CampaignBloggersDescription: 'campaign-bloggers-description',
  // CampaignBloggers: 'campaign-bloggers',
  // CampaignSelection: 'campaign-selection',

  AgencyCabinetRoot: 'agency-cabinet',
  AgencyCabinetCampaign: 'agency-cabinet-campaign',
  AgencyCabinetCampaignList: 'agency-cabinet-campaigns',
  AgencyCabinetDescription: 'agency-cabinet-description',
  AgencyCabinetGeneralDescription: 'agency-cabinet-general-description',
  AgencyCabinetBloggersDescription: 'agency-cabinet-bloggers-description',
  AgencyCabinetBloggers: 'agency-cabinet-bloggers',
  AgencyCabinetSelection: 'agency-cabinet-selection',
};

Object.freeze(RouteName);

export { RouteName };
