<script>
export default {
  name: "BaseCardItemContainer",
  props: {
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<template>
  <div class="card" :class="{ cardSelected: isSelected }">
    <slot name="top"></slot>
    <slot name="content" class="card-content"></slot>
    <slot name="controls" class="controls"></slot>
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";
@import "@/style/colors.scss";
@import "@/style/breakpoints.scss";

.card {
  min-width: 270px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: none;
  border-radius: 18px;
  gap: 20px;
  color: $primary-black;
  background-color: $primary-white;
  box-shadow: 1px 4px 21px 0px rgba(46, 70, 111, 0.12);

  @media (max-width: 280px) {
    min-width: 240px;
    width: 240px;
  }

  @include for-big-desktop-up {
    min-width: 320px;
    max-width: 320px;
  }

  &Selected {
    border: 1px solid $brand-color;
  }

  &-content {
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 10px;
    margin-top: 13px;
    margin-bottom: 20px;

    @include for-phone-only {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

.point {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  line-height: 20px;
  font-size: 14px;

  @include for-big-desktop-up {
    font-size: 16px;
  }

  &-heading {
    @include raleway-regular;
    max-width: 50%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 5px;
    color: $secondary-grey;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    svg {
      min-width: 16px;
      height: 16px;

      @include for-big-desktop-up {
        min-width: 18px;
        height: 18px;
      }
    }
  }

  &-value {
    @include raleway-medium;
    max-width: 50%;
    text-align: end;
    color: $primary-black;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.controls {
  min-width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
</style>