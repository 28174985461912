import ApiService from "@/api/ApiService";

const api = new ApiService();

export default {
  namespaced: true,
  actions: {
    async fetchCommentsByInfluencer({ commit }, { id, query }) {
      return await api
        .get(`/api/testimonial/${id}${query ? `?${query}` : ""}`)
        .then(({ data }) => data);
    },
    async postCommentByInfluencer({ commit }, { id, text }) {
      return await api
        .post(`/api/testimonial/${id}`, { text })
        .then(({ data }) => data);
    },
  },
};
