<script>
import colors from "@/style/colors";

import BaseHeadingBlock from "@/components/menu/BaseHeadingBlock.vue";
import BaseExcelButton from "@/components/ui/BaseExcelButton.vue";
import BaseSettingsButton from "@/components/ui/BaseSettingsButton.vue";
import BaseButton from "@/components/ui/BaseButton.vue";
import BaseDropdown from "@/components/ui/BaseDropdown.vue";
import BaseInfoIcon from "../ui/BaseInfoIcon.vue";

export default {
  name: "CampaignHeadingBlock",
  components: {
    BaseButton,
    BaseDropdown,
    BaseExcelButton,
    BaseHeadingBlock,
    BaseInfoIcon,
    BaseSettingsButton,
  },
  props: {
    title: { type: String, default: "" },
    isAttachBtnActive: { type: Boolean, required: true },
    hideTaskManager: { type: Boolean, required: false },
    numberOfBloggers: { type: Number, required: true },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      colors: colors,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    isIconOnly() {
      return this.windowWidth < 600;
    },
    isSettingsBtnVisible() {
      return this.windowWidth >= 1350;
    },
    isExcelBtnVisible() {
      return this.windowWidth >= 600;
    },
    borderlessBtnStyles() {
      return { width: "max-content", height: "30px", padding: "0px 10px" };
    },
  },
  emits: [
    "on-doc-btn-click",
    "on-settings-btn-click",
    "on-task-btn-click",
    "on-attach-btn-click",
  ],
};
</script>

<template>
  <div class="wrapper">
    <div v-if="title" class="title-container">
      <h1 class="title-text">{{ title }}</h1>

      <div class="title-infoIcon">
        <BaseInfoIcon :tooltipText="$gettext('info')" />
      </div>
    </div>

    <div class="buttons">
      <BaseButton
        v-if="!hideTaskManager"
        :iconOnly="isIconOnly"
        :title="$gettext('Task manager')"
        colorMode="secondary"
        icon="bxs:folder-open"
        @click="$emit('on-task-btn-click')"
      />

      <BaseExcelButton
        v-if="isExcelBtnVisible"
        :color="colors.primaryGrey"
        @on-click="$emit('on-doc-btn-click')"
      />
      <BaseSettingsButton
        v-if="isSettingsBtnVisible"
        @on-click="$emit('on-settings-btn-click')"
      />
    </div>

    <div class="filters">
      <!-- TODO: for status sorting -->
      <!-- <BaseDropdown
        title="All statuses"
        icon="bx-chevron-down"
        :customStyle="borderlessBtnStyles"
      >
        <b-dropdown-item>
          <translate>status 1</translate>
        </b-dropdown-item>

        <b-dropdown-item>
          <translate>status 2</translate>
        </b-dropdown-item>

        <b-dropdown-item>
          <translate>status 3</translate>
        </b-dropdown-item>
      </BaseDropdown> -->

      <div>
        <span
          v-if="isAttachBtnActive"
          :style="borderlessBtnStyles"
          class="borderlessBtn"
        >
          <translate>Selected bloggers</translate>: {{ numberOfBloggers }}
        </span>

        <span v-else :style="borderlessBtnStyles" class="borderlessBtn">
          <translate>All bloggers</translate>: {{ numberOfBloggers }}
        </span>
      </div>

      <button
        v-if="isAttachBtnActive"
        :style="borderlessBtnStyles"
        class="borderlessBtn borderlessBtn-underline"
        @click.prevent="$emit('on-attach-btn-click')"
      >
        <translate>Attach description to selected</translate>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";
@import "@/style/breakpoints.scss";
@import "@/style/colors.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 20px;
  row-gap: 10px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }

  @include for-phone-only {
    flex-wrap: nowrap;
    grid-template-rows: 1fr;
  }

  & .title {
    &-container {
      display: flex;
      flex-wrap: nowrap;
      gap: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-text {
      @include title;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-infoIcon {
      height: 42px;
      display: flex;
      align-items: center;

      @include for-phone-only {
        height: 36px;
      }

      @include for-big-desktop-up {
        height: 54px;
      }
    }
  }

  & .filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    grid-column: 1 / 3;
  }

  & .buttons {
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    height: 44px;

    @include for-phone-only {
      align-self: flex-start;
    }

    @include for-big-desktop-up {
      height: 48px;
    }
  }
}

.borderlessBtn {
  @include raleway-regular;
  @include fontSize-small;
  display: flex;
  align-items: center;
  color: $primary-grey;
  white-space: nowrap;
  text-align: center;

  &-underline {
    @include raleway-medium;
    text-decoration: underline;
    text-underline-offset: 2px;
    color: $primary-blue;
  }
}
</style>