<script>
import BaseEmptyContainer from '@/components/ui/BaseEmptyContainer.vue';

export default {
  name: 'BaseEmptyErrorContainer',
  components: {
    BaseEmptyContainer,
  },
  methods: {
    redirectToCampaigns() {
      this.$router.push({
        name: 'campaigns',
      });
    },
  },
};
</script>

<template>
  <BaseEmptyContainer
    :button-text="$gettext('Перейти к кампаниям')"
    :description="$gettext('An unexpected error has occurred. Please try again later.')"
    :title="$gettext('Oops! Something went wrong')"
    @on-click="redirectToCampaigns"
  />
</template>
