<script>
import 'vueperslides/dist/vueperslides.css';

import { CampaignContentSection } from '@/components/campaign';
import ModalError from '@/components/menu/ModalError';
import { Loading } from '@/components/ui';
import BaseProvidedModal from '@/components/ui/BaseProvidedModal.vue';
import colors from '@/style/colors';

export default {
  name: 'ContentModal',
  components: { BaseProvidedModal, CampaignContentSection, Loading, ModalError },
  props: {
    modalId: { type: String, required: true },
    drafts: { type: Array, required: true, default: () => [] },
    screenshots: { type: Array, required: true, default: () => [] },
    reports: { type: Array, required: true, default: () => [] },
    link: { type: String, required: false, default: '' },
  },
  emits: ['on-hide'],
  data() {
    return {
      state: 'isLoading', // isLoading, isError, isSuccess, isEmpty
      taskList: null,
      campaignId: null,
      errorModalId: 'errorModal',
      colors: colors,
    };
  },
  computed: {},
  created() {
    this.state = 'isSuccess';
  },
};
</script>

<template>
  <BaseProvidedModal
    :id="modalId"
    :title="$gettext('Content')"
    full
    size="xl"
    @hide="$emit('on-hide')"
  >
    <ModalError :modal-id="errorModalId" @cancel="$bvModal.hide(errorModalId)" />
    <div v-if="state === 'isLoading'" class="container-loading">
      <div class="loading">
        <Loading type="round" />
      </div>
    </div>

    <div v-if="state === 'isSuccess'" class="wrapper">
      <div class="header-description">
        <translate>Drafts and screenshots from the blogger</translate>
      </div>

      <CampaignContentSection
        v-if="drafts"
        :items="drafts"
        :title="$gettext('Drafts')"
        ref-name="draftsRef"
        type="content"
      />

      <CampaignContentSection
        v-if="screenshots"
        :items="screenshots"
        :title="$gettext('Screenshots')"
        ref-name="screenshotsRef"
        type="content"
      />

      <CampaignContentSection
        v-if="link"
        :link="link"
        :title="$gettext('Publication')"
        type="link"
      />

      <CampaignContentSection
        v-if="reports"
        :items="reports"
        :title="$gettext('Reports')"
        ref-name="reportsRef"
        type="content"
      />
    </div>
  </BaseProvidedModal>
</template>

<style lang="scss" scoped>
  @import '@/style/colors.scss';
  @import '@/style/fonts.scss';
  @import '@/style/breakpoints.scss';

  .container-loading {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    background-color: $primary-background;
    opacity: 0.7;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include for-phone-only {
      padding: 0;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-title {
      @include raleway-bold;
      font-size: 28px;
      line-height: 36px;
      color: $primary-black;
      margin: 0;

      @include for-phone-only {
        font-size: 24px;
        line-height: 28px;
      }
    }

    &-description {
      @include raleway-regular;
      font-size: 14px;
      color: $primary-grey;
      margin: 0;

      @include for-phone-only {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
  }
</style>
