<script>
import Loading from '@/components/ui/Loading.vue';

export default {
  components: { Loading },
  props: {
    text: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    variant: {
      validator: (v) => ['primary', 'primary-light', 'dangerous'].includes(v),
      default: 'primary',
    },
    size: {
      validator: (v) => ['md', 'lg'].includes(v),
      default: 'md',
    },
  },
  emits: ['click'],
  computed: {
    buttonClass() {
      return {
        'base-solid-button': true,
        'base-solid-button--loading': this.loading,
        [`base-solid-button--${this.variant}`]: true,
        [`base-solid-button--${this.size}`]: true,
      };
    },
  },
};
</script>

<template>
  <button
    :class="buttonClass"
    :disabled="disabled && !loading"
    v-bind="$attrs"
    @click="$emit('click')"
  >
    <template v-if="loading">
      <Loading />
    </template>
    <template v-else>
      {{ text }}
      <slot />
    </template>
  </button>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/breakpoints.scss' as breakpoints;
  @use '@/style/colors.scss' as colors;

  .base-solid-button {
    @include fonts.raleway-semibold;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s;
    user-select: none;

    &.base-solid-button--md {
      @include fonts.fontSize-big;
      gap: 12px;
      padding: 12px 20px;
      border-radius: 16px;
      min-height: 48px;
      height: 48px;
      max-height: 48px;
    }

    &.base-solid-button--lg {
      @include fonts.fontSize-big;
      gap: 16px;
      padding: 16px 24px;
      border-radius: 16px;
      min-height: 56px;
      height: 56px;
      max-height: 56px;
    }

    &:focus,
    &:active,
    &:focus-visible,
    &:active:focus,
    &.active:focus {
      box-shadow: none;
      outline: none;
    }

    &:disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &.base-solid-button--primary {
      background-color: colors.$brand-color;
      color: colors.$primary-white;

      &:not(:disabled):hover {
        background-color: colors.$brand-color-dark;
      }

      &:not(:disabled):active {
        background-color: #5e3fb6;
      }
    }

    &.base-solid-button--primary-light {
      background-color: colors.$primary-lavender;
      color: colors.$brand-color;

      &:not(:disabled):hover {
        background-color: darken(colors.$primary-lavender, 5%);
      }

      &:not(:disabled):active {
        background-color: darken(colors.$primary-lavender, 10%);
      }
    }

    &.base-solid-button--dangerous {
      background-color: rgba(225, 31, 40, 0.07);
      color: colors.$primary-red;

      &:not(:disabled):hover {
        background-color: rgba(225, 31, 40, 0.15);
      }

      &:not(:disabled):active {
        background-color: rgba(225, 31, 40, 0.2);
      }
    }

    &.base-solid-button--loading {
      pointer-events: none;
      cursor: default;

      :deep(svg) {
        color: colors.$primary-white !important;
        height: 80px !important;
        width: 100px !important;
      }
    }
  }
</style>
