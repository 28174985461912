<script>
import { Icon } from "@iconify/vue2";

import { BaseModal } from "@/components/ui";
import SettingsButton from "@/components/settings/SettingsButton.vue";

export default {
  name: "ModalConfirmation",
  components: {
    BaseModal,
    Icon,
    SettingsButton,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ["confirm"],
};
</script>

<template>
  <BaseModal :modalId="modalId" @on-hide="$bvModal.hide(modalId)">
    <div class="wrapper">
      <div class="content">
        <h3 class="subtitle">{{ title }}</h3>
      </div>

      <div class="controls">
        <SettingsButton :title="$gettext('Отмена')" type="button" @click="$bvModal.hide(modalId)" />
        <SettingsButton :title="$gettext('Yes')" type="button" @click="$emit('confirm')" />
      </div>
    </div>
  </BaseModal>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";

.controls {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;

  @media (max-width: 576px) {
    flex-direction: column;
    row-gap: 10px;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 20px 20px;

  & .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;

    & .subtitle {
      @include raleway-semibold;
      font-size: 18px;
      line-height: 27px;
      margin: 0px;
    }

    & .icon {
      height: 50px;
      width: 50px;
    }
  }
}
</style>
      