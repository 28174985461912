<script>
import BaseProvidedModal from '@/components/ui/BaseProvidedModal.vue';
import BaseSolidButton from '@/components/ui/BaseSolidButton.vue';
import { modalService } from '@/services/modal.service';

export default {
  name: 'DeleteConfirmModal',
  components: { BaseSolidButton, BaseProvidedModal },
  props: {
    modalId: { type: String, required: true },
  },
  computed: {
    modalPayload() {
      return modalService().getState(this.modalId)?.payload ?? {};
    },
    modalState() {
      return {
        sendRequest: this.modalPayload.sendRequest ?? (() => Promise.resolve()),
        rejectRequest: this.modalPayload.rejectRequest ?? (() => Promise.reject()),
        headerTitle:
          this.modalPayload.headerTitle ?? this.$gettext('Are you sure you want to delete?'),
        headerDescription:
          this.modalPayload.headerDescription ?? this.$gettext('This action cannot be undone'),
      };
    },
  },
};
</script>

<template>
  <BaseProvidedModal
    :id="modalId"
    :close-out-backdrop="true"
    :title="modalState.headerTitle"
    size="md"
    title-align="center"
    title-size="md"
  >
    <div class="delete-confirm">
      <div class="delete-confirm__description">
        <translate>{{ modalState.headerDescription }}</translate>
      </div>
      <div class="delete-confirm__footer">
        <BaseSolidButton
          :style="{ 'min-width': '185px' }"
          :text="$gettext('Delete')"
          size="md"
          variant="dangerous"
          @click="modalState.sendRequest"
        />
        <BaseSolidButton
          :style="{ 'min-width': '185px' }"
          :text="$gettext('Cancel')"
          size="md"
          variant="primary"
          @click="modalState.rejectRequest"
        />
      </div>
    </div>
  </BaseProvidedModal>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;
  @use '@/style/breakpoints.scss' as breakpoints;

  .delete-confirm {
    display: flex;
    flex-direction: column;
    gap: 40px;
    min-height: 100%;

    &__description {
      @include fonts.raleway-regular;
      @include fonts.fontSize-big;
      color: colors.$primary-black;
      margin: 0;
      text-align: center;
      line-height: 20px;

      @include breakpoints.for-phone-only {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
  }
</style>
