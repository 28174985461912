export async function poll(
  api,
  url,
  controller,
  paramsCallback,
  responseCallback,
  timeout = 1000
) {
  const signal = controller.signal;

  if (signal.aborted) {
    return;
  }

  try {
    const params = new URLSearchParams(paramsCallback()).toString();
    let response = await api.get(params ? `${url}?${params}` : url, {}, false, {
      signal,
    });
    responseCallback(response);

    if (response.status === 200) {
      await new Promise((resolve) => setTimeout(resolve, timeout));
      await poll(
        api,
        url,
        controller,
        paramsCallback,
        responseCallback,
        timeout
      );
    }
  } catch (e) {
    responseCallback(undefined, e);
  }
}
