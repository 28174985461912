<script>
import { Icon } from "@iconify/vue2";

export default {
  components: {
    Icon,
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: "bi:three-dots",
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    customStyle: {
      type: Object,
      required: false,
    },
  },
  emits: ["on-shown", "on-hidden"],
};
</script>

<template>
  <b-dropdown
    no-caret
    right
    class="dropdownButton"
    :style="customStyle"
    variant="none"
    @hidden="$emit('on-hidden')"
    @shown="$emit('on-shown')"
  >
    <template #button-content>
      <span v-if="title" class="dropdownButton-text">{{ title }}</span>
      <Icon :icon="icon" />
    </template>

    <slot></slot>
  </b-dropdown>
</template>

<style lang="scss" scoped>
@import "@/style/fonts.scss";
@import "@/style/colors.scss";

.dropdownButton {
  height: 100%;
  width: 50px;
  min-width: 50px;

  &:hover {
    * {
      color: $secondary-grey;
    }
  }

  &-text {
    @include raleway-medium;
    @include fontSize-small;
    color: $primary-grey;
    margin-right: 5px;
  }
}

.dropdownButton:deep(button) {
  color: $primary-grey;
  padding: 0;
  margin: 0;
  border: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    border-color: transparent;
  }
}

.dropdownButton:deep(ul) {
  border-radius: 16px;
  border: 1px solid rgba(1, 187, 236, 0.05);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
}

.dropdownButton:deep(a) {
  @include raleway-medium;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 24px;
  font-size: 14px;

  & svg {
    color: #bec2cd;
  }

  &:active {
    background-color: rgba(39, 41, 44, 0.2);
    outline: none;
  }

  &:focus-visible {
    background-color: rgba(39, 41, 44, 0.2);
    outline: none;
  }

  &:hover {
    background-color: $secondary-white-grey;
    outline: none;
  }
}
</style>