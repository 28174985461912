<script>
import isEqual from 'lodash-es/isEqual';
import { createNamespacedHelpers } from 'vuex';

import BaseStatusLabel from '@/components/ui/BaseStatusLabel.vue';
import { SelectCellField } from '@/components/ui/tables';
import { createOfferStatusConfig, OfferStatus } from '@/values/offerStatus';

import { createTablePatchPayload } from '../tableHelpers';

const agencyCabinetStore = createNamespacedHelpers('agencyCabinetModule');

export default {
  name: 'InfluencerStatusCell',
  components: { BaseStatusLabel, SelectCellField },
  props: {
    table: { type: Object, default: () => ({}) },
    row: { type: Object, default: () => ({}) },
    cell: { type: Object, default: () => ({}) },
    columnDefs: { type: Object, default: () => ({}) },
  },
  data: () => ({
    innerValue: null,
  }),
  computed: {
    currentColumn() {
      return this.cell.col?.key ?? '';
    },
    value() {
      return this.cell.value ?? null;
    },
    config() {
      return createOfferStatusConfig({
        [OfferStatus.ApprovedDraft]: this.$gettext('Approved draft'),
        [OfferStatus.ApprovedPrice]: this.$gettext('Approved price'),
        [OfferStatus.ApprovedPublish]: this.$gettext('Approved publish'),
        [OfferStatus.Completed]: this.$gettext('Completed'),
        [OfferStatus.Demo]: this.$gettext('Demo'),
        [OfferStatus.New]: this.$gettext('New'),
        [OfferStatus.Published]: this.$gettext('Published'),
        [OfferStatus.Deleted]: this.$gettext('Deleted'),
        [OfferStatus.Rejected]: this.$gettext('Rejected'),
        [OfferStatus.RejectedDraft]: this.$gettext('Rejected draft'),
        [OfferStatus.RejectedPrice]: this.$gettext('Rejected price'),
        [OfferStatus.RejectedPublish]: this.$gettext('Rejected publish'),
        [OfferStatus.RejectedSpecification]: this.$gettext('Rejected specification'),
        [OfferStatus.SentDraft]: this.$gettext('Sent draft'),
        [OfferStatus.SentPrice]: this.$gettext('Sent price'),
        [OfferStatus.SentSpecification]: this.$gettext('Sent specification'),
      });
    },
    options() {
      return Object.entries(this.config).map(([key, c]) => ({ key, text: c.title }));
    },
  },
  watch: {
    value: {
      handler(v) {
        this.innerValue = v ? { key: v, text: this.config[v]?.title ?? v } : null;
      },
      immediate: true,
    },
  },
  methods: {
    ...agencyCabinetStore.mapActions({ updateRow: 'updateRow' }),
    onRevert() {
      this.innerValue = this.value
        ? { key: this.value, text: this.config[this.value] ?? this.value }
        : null;
    },
    async onSubmit(option = {}) {
      const prevValue = this.value;
      const nextValue = option.key ?? '';

      if (isEqual(prevValue, nextValue) || !nextValue) {
        return;
      }

      const payload = createTablePatchPayload(this.row.originalValue, {
        [this.currentColumn]: nextValue,
      });

      this.innerValue = option;
      const isSubmitted = await this.updateRow(payload);
      !isSubmitted && this.onRevert();
    },
  },
};
</script>

<template>
  <SelectCellField
    :options="options"
    :value="innerValue"
    no-chevron
    @revert="onRevert"
    @submit="onSubmit"
  >
    <template #value>
      <BaseStatusLabel
        v-if="innerValue && innerValue.key"
        :arrow="config[innerValue.key]?.legacy.arrow"
        :arrow-dir="config[innerValue.key]?.legacy.arrowDir"
        :status="innerValue.key"
        :status_translate="config[innerValue.key]?.title"
      />
    </template>
    <template #option="{ option }">
      <BaseStatusLabel
        :arrow="config[option.key]?.legacy.arrow"
        :arrow-dir="config[option.key]?.legacy.arrowDir"
        :status="option.key"
        :status_translate="config[option.key]?.title"
      />
    </template>
  </SelectCellField>
</template>
