import { initializeApp } from "firebase/app";

var firebaseConfig = {
    apiKey: "AIzaSyAfyQ89RHnkPSv6j5BCgSWgCL4GXkPEnKk",
    authDomain: "advytest-f9392.firebaseapp.com",
    projectId: "advytest-f9392",
    storageBucket: "advytest-f9392.appspot.com",
    messagingSenderId: "17727781631",
    appId: "1:17727781631:web:83c7a09bdb5dc081c8aaca",
    measurementId: "G-CWZR2YX5RQ"
};

export const app = initializeApp(firebaseConfig)