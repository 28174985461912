<script>
import BaseStatusLabel from "@/components/ui/BaseStatusLabel.vue";

import defaultIcon from "@/assets/blogger-img3.png";

export default {
  props: {
    image: {
      type: String,
      required: false,
    },
    status: {
      type: String,
      required: false,
    },
    status_translate: {
      type: String,
      required: false,
    },
    size: {
      type: Number,
      required: false,
      default: 90,
    },
    cursorPointer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    BaseStatusLabel,
  },
  computed: {
    imageSize() {
      return `width: ${this.size}px; height: ${this.size}px`;
    },
    defaultIcon() {
      return defaultIcon;
    },
  },
  emits: ["on-click"],
};
</script>

<template>
  <div
    class="image-wrapper"
    :style="{ imageSize, cursor: cursorPointer ? 'pointer' : '' }"
    @click.prevent="$emit('on-click')"
  >
    <img v-if="image" :src="image" :style="imageSize" />
    <img v-else :src="defaultIcon" :style="imageSize" />
    <BaseStatusLabel v-if="status_translate" :status="status" :status_translate="status_translate" class="status" />
  </div>
</template>

<style lang="scss" scoped>
.image-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  transition: 0.2s;

  & img {
    border-radius: 50%;
  }

  & .status {
    position: absolute;
    bottom: -12px;
  }
}
</style>