import { RouteName } from '@/values/routeName';

function createMeta(pageName = 'Campaigns') {
  return {
    title: ['Advy.ai', pageName].filter(Boolean).join(' - '),
    requiresAuth: true,
    isAgencyCabinet: false,
  };
}

export default [
  {
    path: '/campaigns',
    name: RouteName.Campaigns,
    component: () => import('../views/campaigns/index.vue'),
    meta: createMeta('Campaigns'),
    children: [
      {
        path: 'onboarding',
        name: 'onboarding',
        component: () => import('../views/onboarding/index.vue'),
        meta: createMeta('Onboarding Start'),
      },
    ],
  },
  {
    path: '/campaign/:id',
    name: RouteName.Campaign,
    component: () => import('../views/campaign/index.vue'),
    redirect: { name: RouteName.Description },
    meta: createMeta('Campaign'),
    props: true,
    children: [
      {
        path: 'description',
        name: RouteName.Description,
        component: () => import('../views/campaign/description/index.vue'),
        meta: createMeta('Campaign - Overview'),
        children: [
          {
            path: 'general',
            name: RouteName.GeneralDescription,
            component: () => import('../views/campaign/description/general.vue'),
            meta: createMeta('Campaign - General overview'),
            props: true,
          },
          {
            path: 'bloggers',
            name: RouteName.BloggersDescription,
            component: () => import('../views/campaign/description/bloggers.vue'),
            meta: createMeta('Campaign - Bloggers overview'),
            props: true,
          },
        ],
      },
      {
        path: 'bloggers',
        name: RouteName.Bloggers,
        component: () => import('../views/campaign/bloggers.vue'),
        meta: createMeta('Campaign - Bloggers'),
        props: true,
      },
      {
        path: 'selection',
        name: RouteName.Selection,
        component: () => import('../views/campaign/selection.vue'),
        meta: createMeta('Campaign - Selection'),
        props: true,
      },
    ],
  },
  {
    path: '/campaign/:id/init',
    name: RouteName.Init,
    component: () => import('../views/campaign/initView.vue'),
    meta: createMeta('Campaign - Overview'),
  },
];
