import { modalService } from '@/services/modal.service';
import store from '@/store';
import { ModalName } from '@/values/modalName';
import { WaitState } from '@/values/waitState';

export function createTablePatchPayload(currentState, changes) {
  const requiredFields = {
    id: currentState.id,
    _revision: currentState._revision,
  };

  return Object.assign({}, requiredFields, changes);
}

export function createMultipleTablePatchPayload(editRows, changesArr) {
  return editRows.map((row) => {
    const changes = changesArr.reduce((acc, change) => {
      return Object.assign(acc, change);
    }, {});

    return createTablePatchPayload(row, changes);
  });
}

export async function callConfirmDeletionModal(api, url, payload, textOption) {
  return new Promise((resolve, reject) => {
    modalService().open(ModalName.DeleteConfirmModal, {
      sendRequest: async () => {
        try {
          store.commit('agencyCabinetModule/setWaitState', WaitState.isLoading);
          const res = await api.delete(url, payload);
          res && resolve();
        } catch {
          store.commit('agencyCabinetModule/setWaitState', WaitState.isFailure);
        } finally {
          modalService().close(ModalName.DeleteConfirmModal);
        }
      },
      rejectRequest: () => {
        modalService().close(ModalName.DeleteConfirmModal);
        reject();
      },
      headerTitle: textOption.headerTitle,
      headerDescription: textOption.headerDescription,
    });
  });
}
