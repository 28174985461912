import { toArray, toNumber } from './normalize';

export function isEqual(a, b) {
  if (typeof a === 'object' && typeof b === 'object') {
    return JSON.stringify(a) === JSON.stringify(b);
  }
  return a === b;
}

export function isNull(a) {
  return a === null || a === undefined;
}

export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

/**
 * Create plural text for given count
 * @param {number} count - count of items
 * @param {string[]} texts - array of strings ['one', 'two-four', 'five and more']
 * @param {string} postfix - string after count
 * @returns {string} - string with plural form
 */
export function queryPluralText(count, texts = ['', '', ''], postfix = '') {
  const countValue = toNumber(count);
  const textsValue = toArray(texts);

  const textForOne = textsValue[0] ?? '';
  const textForTwoFour = textsValue[1] ?? '';
  const textForFiveAndMore = textsValue[2] ?? textForTwoFour;

  if (count % 10 === 1 && count % 100 !== 11) {
    return `${countValue}${postfix} ${textForOne}`;
  } else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
    return `${countValue}${postfix} ${textForTwoFour}`;
  } else {
    return `${countValue}${postfix} ${textForFiveAndMore}`;
  }
}
