<script>
import colors from '@/style/colors';

export default {
  name: 'BaseCheckbox',
  props: {
    checked: { type: Boolean, default: false },
    indeterminate: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    size: { type: Number, required: false, default: 14 },
  },
  emits: ['on-check', 'on-uncheck', 'update:checked'],
  data() {
    return {
      colors: colors,
    };
  },
  methods: {
    onChange($event) {
      const value = $event.target.checked;
      this.$emit('update:checked', value);
      this.$emit(value ? 'on-check' : 'on-uncheck', value);
    },
  },
};
</script>

<template>
  <label :style="{ '--base-checkbox-size': size + 'px' }" data-part="label" data-scope="checkbox">
    <input
      :checked="checked"
      :disabled="disabled"
      :indeterminate.prop="indeterminate"
      data-part="input"
      data-scope="checkbox"
      type="checkbox"
      v-bind="$attrs"
      @change="onChange"
    >
    <span
      :data-checked="Boolean(checked || indeterminate)"
      :data-disabled="disabled"
      :data-indeterminate="indeterminate"
      aria-hidden="true"
      data-part="root"
      data-scope="checkbox"
    >
      <template v-if="indeterminate">
        <svg
          data-part="icon"
          data-scope="checkbox"
          fill="currentColor"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
        </svg>
      </template>
      <template v-if="checked">
        <svg
          data-part="icon"
          data-scope="checkbox"
          fill="currentColor"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"
          />
        </svg>
      </template>
    </span>
  </label>
</template>

<style lang="scss" scoped>
  @use '@/style/headless/checkboxes';
</style>
