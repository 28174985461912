export default {
  brandColor: '#825AF9',
  brandColorDark: '#704CDB',
  brandColorGradientEnd: '#C1B9FA',
  brandColorLight: 'rgba(130, 90, 249, 0.08)',
  primaryLavender: '#F5F2FF',
  primaryLavenderDark: '#F0EBFE',
  primaryBlack: '#27292c',
  primaryGrey: '#636d79',
  primaryBlue: '#367bf2',
  primaryWhite: '#ffffff',
  primaryBackground: '#F9F9FA',
  primaryBackgroundDark: '#F1F1F5',
  primaryRed: '#E11F28',

  secondaryGrey: '#3f4247',
  secondaryLightGrey: '#B8BCC0',
  secondaryLightBrand: '#FBFBFF',
  secondaryWhiteGrey: '#CFD4D9',
  secondaryRed: 'rgb(241, 76, 76)',
}
