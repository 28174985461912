<script>
import { createNamespacedHelpers, mapState } from "vuex";
import { API_HOST } from "@/config";
import { Icon } from "@iconify/vue2";
import { processTopUp } from "@/services/paymentService.js";

const { mapActions } = createNamespacedHelpers("financeModule");

import {
  BaseButton,
  BaseDropdown,
  BaseModalContainer,
  BaseViewButton,
  Loading,
} from "@/components/ui";
import ModalError from "@/components/menu/ModalError.vue";

export default {
  name: "BalanceWidget",
  components: {
    BaseButton,
    BaseDropdown,
    BaseModalContainer,
    BaseViewButton,
    Icon,
    Loading,
    ModalError,
  },
  data() {
    return {
      state: "default", // isLoading, isError
      availableBalance: null,
      balance: null,
      frozenBalance: null,
      topUpAmount: null,
      errorModalId: "errorModal",
    };
  },
  created() {
    this.getBalance()
      .then((response) => {
        const data = response.data;
        this.availableBalance = data.available_balance_advertiser;
        this.balance = data.balance;
        this.frozenBalance = data.frozen_balance_advertiser;
      })
      .catch(() => this.showErrorModal());
  },
  computed: {
    ...mapState({ accountId: "accountId" }),
  },
  methods: {
    ...mapActions({
      getBalance: "getBalance",
      getPaymentProviders: "getPaymentProviders",
      getPaymentMethods: "getPaymentMethods",
      addPaymentMethod: "addPaymentMethod",
      callDeposit: "callDeposit",
    }),
    async handleBtnClick() {
      this.state = "isLoading";

      try {
        const link = await processTopUp(this.accountId, window.location.href, this.topUpAmount);
        window.location.href = link;
      } catch (err) {
        this.state = "isError";
        console.error(err);
        this.showErrorModal();
      }
    },
    showErrorModal() {
      this.$bvModal.show(this.errorModalId);
    },
  },
};
</script>

<template>
  <div>
    <div v-if="state === 'isLoading'" class="loadingContainer">
      <Loading type="round" />
    </div>

    <b-dropdown class="dropdownButton" variant="none" no-caret>
      <template #button-content>
        <div class="dropdownButton-buttons">
          <div class="dropdownButton-pill">
            <span class="point"><translate>Your balance</translate>:</span>
            <div>
              <span class="amount">$ {{ availableBalance }}</span>
              <Icon icon="bx-chevron-down" />
            </div>
          </div>

          <div class="dropdownButton-circle">
            <Icon icon="bx-plus" width="24" height="24" />
          </div>
        </div>
      </template>

      <div class="content">
        <div class="content-points">
          <div class="content-points__item">
            <span class="point"><translate>Total balance</translate>:</span>
            <span class="amount">$ {{ balance }}</span>
          </div>

          <div class="content-points__item">
            <span class="point"><translate>Frozen</translate>:</span>
            <span class="amount">$ {{ frozenBalance }}</span>
          </div>
        </div>

        <div class="inputContainer">
          <span class="inputContainer-currency">$</span>
          <input class="inputContainer-input" type="number" v-model="topUpAmount" />
        </div>

        <BaseViewButton
          :disabled="!topUpAmount"
          @on-click="handleBtnClick"
          :text="$gettext('Top up balance')"
        />
      </div>
    </b-dropdown>

    <ModalError :modalId="errorModalId" @cancel="$bvModal.hide(errorModalId)" />
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/colors.scss";
@import "@/style/fonts.scss";
@import "@/style/breakpoints.scss";

:deep(.btn) {
  padding: 0;
}

:deep(.dropdown-menu) {
  width: 100%;
}

.dropdownButton {
  height: 100%;

  &-buttons {
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
  }

  &-pill {
    min-width: 200px;
    height: 48px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: 12px 20px;
    border-radius: 32px;
    background-color: $primary-white;

    & svg {
      color: $secondary-white-grey;
      margin-left: 5px;
    }
  }

  &-circle {
    height: 48px;
    min-height: 48px;
    width: 48px;
    min-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    color: $primary-white;
    background-color: $brand-color;
    transition: 0.2s;

    &:hover {
      background-color: $brand-color-dark;
    }
  }

  &-text {
    @include raleway-medium;
    @include fontSize-small;
    color: $primary-grey;
    margin-right: 5px;
  }
}

.content {
  display: flex;
  gap: 20px;
  flex-direction: column;

  &-points {
    display: flex;
    gap: 10px;
    flex-direction: column;

    &__item {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.inputContainer {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  border-radius: 12px;

  color: $primary-black;
  border: 1px solid $secondary-white-grey;
  text-align: end;

  &-currency {
    @include raleway-medium;
    font-size: 12px;
  }

  &-input {
    @include raleway-regular;
    height: 100%;
    width: 100%;
    font-size: 16px;
    text-align: end;
    border: none;
    direction: ltr;

    &:focus-visible {
      outline: none;
    }

    &:disabled {
      opacity: 0.3;
    }
  }
}

.point {
  @include raleway-regular;
  color: $primary-grey;
  font-size: 14px;
  line-height: 24px;
  margin-right: 5px;
}

.amount {
  @include raleway-bold;
  color: $primary-black;
  font-size: 14px;
  line-height: 24px;
}

.dropdownButton:deep(button) {
  border: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    border-color: transparent;
  }
}

.dropdownButton:deep(ul) {
  min-width: 260px;
  border-radius: 16px;
  padding: 20px;
  border: none;
  box-shadow: 1px 4px 24px 0px rgba(48, 42, 111, 0.1);
  background-color: $primary-white;
  margin-top: 6px;
}

.loadingContainer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1001;
  background-color: $primary-lavender;
  opacity: 0.7;
  overflow: hidden;
}
</style>