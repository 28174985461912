import ApiService from '@/api/ApiService'

const api = new ApiService()

const actions = {
  getPrivacyPolicyText(context) {
    return api.get('/api/common/html/privacy')
  },
}

export default {
  namespaced: true,
  actions,
}
