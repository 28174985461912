export default {
  namespaced: true,
  state: () => ({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  }),
  mutations: {
    setWindowWidth(state, width) {
      state.windowWidth = width;
    },
    setWindowHeight(state, height) {
      state.windowHeight = height;
    },
  },
  getters: {
    windowWidth: (state) => state.windowWidth,
    windowHeight: (state) => state.windowHeight,
    isMobile: (state) => state.windowWidth <= 768,
    isTablet: (state) => state.windowWidth > 768 && state.windowWidth < 1280,
    isScreenMd: (state) => state.windowWidth >= 768 && state.windowWidth < 1024,
    isScreenLg: (state) => state.windowWidth >= 1024 && state.windowWidth < 1280,
    isScreenXl: (state) => state.windowWidth >= 1280 && state.windowWidth < 1440,
    isScreen2Xl: (state) => state.windowWidth >= 1440 && state.windowWidth < 1600,
    isScreen3Xl: (state) => state.windowWidth >= 1600 && state.windowWidth < 1920,
    isScreenXXl: (state) => state.windowWidth >= 1920,
  },
};
