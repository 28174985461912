const WaitState = {
  isLoading: 'isLoading',
  isSuccess: 'isSuccess',
  isFailure: 'isFailure',
  default: 'default',
  isOutdated: 'isOutdated',
};

Object.freeze(WaitState);

export { WaitState };
