<script>
export default {
  name: 'BaseTextRadioButton',
  props: {
    id: { type: String, required: true },
    name: { type: String, required: true },
    label: { type: String, required: true },
    checked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
};
</script>

<template>
  <label
    :data-active="checked"
    :data-disabled="disabled"
    :for="id"
    data-part="label"
    data-scope="text-radio-button"
  >
    <input
      :id="id"
      :checked="checked"
      :disabled="disabled"
      :name="name"
      data-part="input"
      data-scope="text-radio-button"
      type="radio"
      @click="$emit('change', id)"
    >
    {{ label }}
  </label>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  [data-scope='text-radio-button'] {
    &[data-part='label'] {
      @include fonts.fontSize-normal;
      @include fonts.raleway-regular;

      border-radius: 100px;
      background-color: colors.$primary-white;
      color: colors.$primary-grey;
      padding: 8px 20px;
      margin: 0;
      cursor: pointer;
      transition: all 0.3s;

      &[data-active='true'] {
        @include fonts.raleway-semibold;
        background-color: colors.$brand-color;
        color: colors.$primary-white;
      }

      &[data-disabled='true'] {
        cursor: not-allowed;
        opacity: 0.65;

        &:hover {
          background-color: transparent;
          color: colors.$primary-grey;
        }
      }

      &:hover {
        background-color: colors.$brand-color;
        color: colors.$primary-white;
      }
    }

    &[data-part='input'] {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
</style>
