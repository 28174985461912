<script>
import { Icon } from '@iconify/vue2';
import html2pdf from 'html2pdf.js';
import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import { createNamespacedHelpers } from 'vuex';

import ModalError from '@/components/menu/ModalError';
import TaskTemplate from '@/components/reports/TaskTemplate.vue';
import { BaseButton, BaseDropdown, BaseViewButton, Loading } from '@/components/ui';
import { formatDateString, isSecondDateLater } from '@/functions/dateUtils';
import { downloadFile } from '@/functions/download';
import colors from '@/style/colors';
import { ModalName } from '@/values/modalName';

const { mapActions } = createNamespacedHelpers('campaignModule');

export default {
  name: 'TaskManagerModalBody',
  components: {
    BaseButton,
    BaseDropdown,
    BaseViewButton,
    DatePicker,
    Icon,
    Loading,
    ModalError,
  },
  props: {
    isButtonIconOnly: { type: Boolean, required: true },
  },
  data: () => ({
    state: 'isLoading', // isLoading, isError, isSuccess
    screen: null, // empty, data, edit, new, view
    campaignId: null,
    taskList: [],
    colors: colors,
    highlightedTaskId: null,
    isInputDisabled: true,
    dates: null,
    currentName: '',

    messagesMap: null,

    taskForm: {
      name: '',
      description: '',
      must_have: '',
      must_not_have: '',
      start_date: null,
      end_date: null,
    },
    errors: {
      name: '',
      description: '',
      must_have: '',
      must_not_have: '',
      date: '',
    },
    errorModalId: 'errorModal',
  }),
  computed: {
    ModalName() {
      return ModalName;
    },
    dateRange() {
      if (this.taskForm.start_date && this.taskForm.end_date) {
        return this.taskForm.start_date + '/' + this.taskForm.end_date;
      }
      return this.$gettext('Date range') + '*';
    },
    isFormValid() {
      if (!isSecondDateLater(this.taskForm.start_date, this.taskForm.end_date)) {
        return false;
      }

      if (this.taskForm.name.trim() === this.currentName.trim()) {
        return false;
      }
      const errors = Object.values(this.errors)
        .map((v) => v.trim())
        .filter(Boolean);
      return !errors.length && this.taskForm.start_date && this.taskForm.end_date;
    },
  },
  watch: {
    dates(value) {
      if (!Array.isArray(value)) {
        value = [];
      }

      const [startDate, endDate] = value;

      if (startDate === null && endDate === null) {
        this.taskForm.start_date = null;
        this.taskForm.end_date = null;
        return;
      }

      this.taskForm.start_date = formatDateString(startDate);
      this.taskForm.end_date = formatDateString(endDate);
      this.validateDates();
    },
    'taskForm.name'(value) {
      this.validateName(value);
    },
    'taskForm.description'(value) {
      this.validateDescription(value);
    },
    'taskForm.must_have'(value) {
      this.validateMustHave(value);
    },
    'taskForm.must_not_have'(value) {
      this.validateMustNotHave(value);
    },
  },
  created() {
    this.messagesMap = {
      required: this.$gettext('Required field'),
      maxLength: this.$gettext('Maximum {{number}} characters'),
      incorrectPeriod: this.$gettext('Period incorrect'),
    };
    this.getState();
  },
  methods: {
    ...mapActions({
      getTechnicalDescriptionList: 'getTechnicalDescriptionList',
      createTechnicalDescription: 'createTechnicalDescription',
      deleteTechnicalDescription: 'deleteTechnicalDescription',
      publishTechnicalDescription: 'publishTechnicalDescription',
      editTechnicalDescription: 'editTechnicalDescription',
    }),
    async createReport(data) {
      const Template = Vue.extend(TaskTemplate);
      const component = new Template({ propsData: { data } });
      component.$mount();

      const task = data.name.split(' ').join('_');
      const fileName = `task_${task}_details.pdf`;

      const options = {
        margin: 3,
        html2canvas: { scale: 4 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
      };

      try {
        const converter = html2pdf().set(options);
        const processPdf = converter.from(component.$el).toPdf();
        const downloadUri = await processPdf.outputPdf('bloburi');

        downloadFile(downloadUri, fileName);
      } finally {
        component.$destroy();
      }
    },
    getState() {
      this.state = 'isLoading';
      this.campaignId = this.$route.params.id;

      this.getTechnicalDescriptionList({ campaignId: this.campaignId })
        .then((r) => {
          this.taskList = Array.isArray(r.data) ? r.data : [];
          this.screen = this.taskList.length > 0 ? 'data' : 'empty';
          this.state = 'isSuccess';
        })
        .catch(() => {
          this.state = 'isError';
          this.showErrorModal();
        });
    },
    createTask() {
      if (!this.validate()) return;
      this.state = 'isLoading';

      const data = {
        campaignId: this.campaignId,
        body: this.taskForm,
      };

      this.createTechnicalDescription(data)
        .then(() => this.getState())
        .catch(() => {
          this.state = 'isError';
          this.showErrorModal();
        });
    },
    deleteTask(taskId) {
      this.state = 'isLoading';

      const data = {
        campaignId: this.campaignId,
        body: { id: taskId },
      };

      this.deleteTechnicalDescription(data)
        .then(() => this.getState())
        .catch(() => {
          this.state = 'isError';
          this.showErrorModal();
        });
    },
    editTask() {
      if (!this.validate()) return;
      this.state = 'isLoading';

      const data = {
        campaignId: this.campaignId,
        body: this.taskForm,
      };

      this.editTechnicalDescription(data)
        .then(() => this.getState())
        .catch(() => {
          this.state = 'isError';
          this.showErrorModal();
        });
    },
    editAndPublishTask() {
      if (!this.validate()) return;
      this.state = 'isLoading';

      const data = {
        campaignId: this.campaignId,
        body: this.taskForm,
      };

      this.editTechnicalDescription(data)
        .then(() => this.publishTask(this.taskForm.id))
        .catch(() => {
          this.state = 'isError';
          this.showErrorModal();
        });
    },
    publishTask(taskId) {
      this.state = 'isLoading';

      const data = {
        campaignId: this.campaignId,
        body: { id: taskId, status: 'published' },
      };

      this.publishTechnicalDescription(data)
        .then(() => this.getState())
        .catch(() => {
          this.state = 'isError';
          this.showErrorModal();
        });
    },
    createAndPublishTask() {
      if (!this.validate()) return;
      this.state = 'isLoading';

      const data = {
        campaignId: this.campaignId,
        body: this.taskForm,
      };

      this.createTechnicalDescription(data)
        .then((response) => {
          const taskId = response.data.id;
          this.publishTask(taskId);
        })
        .catch(() => {
          this.state = 'isError';
          this.showErrorModal();
        });
    },
    duplicateTask(task) {
      this.state = 'isLoading';
      this.taskForm = task;
      this.currentName = task.name;
      this.taskForm.name = task.name + ' (copy)';
      this.taskForm.status = 'draft';
      this.highlightedTaskId = null;
      this.screen = 'new';
      this.state = 'isSuccess';
    },
    showErrorModal() {
      this.$bvModal.show(this.errorModalId);
    },
    handleDropdownShow(taskId) {
      this.highlightedTaskId = taskId;
    },
    handleDropdownHide() {
      this.highlightedTaskId = null;
    },
    goToNewTask() {
      this.taskForm = {
        name: 'New task',
        description: '',
        must_have: '',
        must_not_have: '',
        start_date: null,
        end_date: null,
      };
      this.dates = null;
      this.highlightedTaskId = null;
      this.screen = 'new';
      this.$nextTick(() => {
        this.errors = {
          name: '',
          description: '',
          must_have: '',
          must_not_have: '',
          date: '',
        };
      });
    },
    goToEditTask(task, screen = 'edit') {
      this.state = 'isLoading';
      this.taskForm = task;
      this.currentName = task.name;
      this.highlightedTaskId = null;
      this.screen = screen;
      this.state = 'isSuccess';
      this.errors = {
        name: '',
        description: '',
        must_have: '',
        must_not_have: '',
        date: '',
      };
    },
    disabledBeforeDate(date) {
      const today = new Date().setHours(0, 0, 0, 0);
      return date < new Date(today);
    },
    validate() {
      const form = this.taskForm;
      this.validateName(form.name);
      this.validateDescription(form.description);
      this.validateMustHave(form.must_have);
      this.validateMustNotHave(form.must_not_have);
      this.validateDates();
      return this.isFormValid;
    },
    validateName(value) {
      if (!value.length) {
        this.errors.name = this.messagesMap.required;
      } else if (value.length > 50) {
        this.errors.name = this.messagesMap.maxLength.replace('{{number}}', '50');
      } else {
        this.errors.name = '';
      }
    },
    validateDescription(value) {
      if (!value.length) {
        this.errors.description = this.messagesMap.required;
      } else if (value.length > 5000) {
        this.errors.description = this.messagesMap.maxLength.replace('{{number}}', '5000');
      } else {
        this.errors.description = '';
      }
    },
    validateMustHave(value) {
      if (value.length > 5000) {
        this.errors.must_have = this.messagesMap.maxLength.replace('{{number}}', '5000');
      } else {
        this.errors.must_have = '';
      }
    },
    validateMustNotHave(value) {
      if (value.length > 5000) {
        this.errors.must_not_have = this.messagesMap.maxLength.replace('{{number}}', '5000');
      } else {
        this.errors.must_not_have = '';
      }
    },
    validateDates() {
      const { start_date, end_date } = this.taskForm;

      if (!start_date || !end_date) {
        this.errors.date = this.messagesMap.required;
      } else if (!isSecondDateLater(start_date, end_date)) {
        this.errors.date = this.messagesMap.incorrectPeriod;
      } else {
        this.errors.date = '';
      }
    },
  },
};
</script>

<template>
  <div class="flex flex-column align-items-center w-100 h-100">
    <ModalError :modal-id="errorModalId" @cancel="$bvModal.hide(errorModalId)" />

    <div v-if="state === 'isLoading'" class="container-loading">
      <div class="loading">
        <Loading type="round" />
      </div>
    </div>

    <div v-if="screen === 'empty'" class="container container-empty">
      <div>
        <div class="title-container">
          <h2 class="title-text-primary">
            <translate>Technical description list</translate>
          </h2>
          <span class="title-text-secondary">{{ taskList.length }}</span>
        </div>

        <div class="description-container">
          <p class="description-text">
            <translate>
              Create multiple tasks for bloggers, publish them to make visible and attach to the
              bloggers offer to start a campaign.
            </translate>
          </p>
        </div>
      </div>

      <div class="content">
        <BaseButton
          :title="$gettext('Create new task')"
          icon="bx-plus"
          icon-position="left"
          @click="goToNewTask"
        />
      </div>
    </div>

    <div v-if="screen === 'data'" class="container">
      <div class="data-header">
        <div class="title-container data-title">
          <span class="title-text-primary">
            <translate>Technical description list</translate>
          </span>
          <span class="title-text-secondary">{{ taskList.length }}</span>
        </div>

        <div class="description-container data-description">
          <translate class="description-text" style="max-width: 410px" tag="p">
            Create multiple tasks for bloggers, publish them to make visible and attach to the
            bloggers offer to start a campaign.
          </translate>
        </div>

        <div class="data-header-right data-button">
          <BaseButton
            :icon-only="isButtonIconOnly"
            :title="$gettext('Create new task')"
            icon="bx:plus"
            icon-position="left"
            @click="goToNewTask"
          />
        </div>
      </div>

      <div class="task-list">
        <div
          v-for="task in taskList"
          :key="task.id"
          :style="{
            backgroundColor: highlightedTaskId === task.id ? colors.secondaryLightBrand : null,
          }"
          class="task-item"
        >
          <div class="task-data">
            <Icon
              :color="task.status === 'published' ? colors.brandColor : colors.secondaryWhiteGrey"
              class="task-icon"
              icon="bxs:folder-open"
            />

            <div class="task-info">
              <span class="task-name">{{ task.name }}</span>
              <span class="task-status">{{ task.status }}</span>
            </div>
          </div>

          <div class="task-controls">
            <button
              v-if="task.status === 'draft' && !isButtonIconOnly"
              class="task-edit-btn"
              @click.prevent="goToEditTask(task)"
            >
              <Icon class="task-edit-icon" icon="bx:edit-alt" />
            </button>

            <BaseViewButton
              v-if="task.status === 'draft' && !isButtonIconOnly"
              :text="$gettext('Publish')"
              @on-click="publishTask(task.id)"
            />

            <BaseDropdown @on-shown="handleDropdownShow(task.id)" @on-hidden="handleDropdownHide">
              <b-dropdown-item class="black" @click.prevent="goToEditTask(task, 'view')">
                <Icon icon="bx:search" />
                <translate>View</translate>
              </b-dropdown-item>

              <b-dropdown-item class="black" @click.prevent="duplicateTask(task)">
                <Icon icon="bx:copy" />
                <translate>Duplicate</translate>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="isButtonIconOnly && task.status === 'draft'"
                class="blue"
                @click.prevent="publishTask(task.id)"
              >
                <Icon icon="bxs:folder-open" />
                <translate>Publish</translate>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="isButtonIconOnly && task.status === 'draft'"
                class="black"
                @click.prevent="goToEditTask(task)"
              >
                <Icon icon="bi:pencil-square" />
                <translate>Edit</translate>
              </b-dropdown-item>

              <b-dropdown-item class="black" @click.prevent="createReport(task)">
                <Icon icon="ph:file-pdf" />
                <translate>Download PDF</translate>
              </b-dropdown-item>

              <b-dropdown-item class="red" @click.prevent="deleteTask(task.id)">
                <Icon icon="bi:trash" />
                <translate>Delete</translate>
              </b-dropdown-item>
            </BaseDropdown>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="screen === 'edit' || screen === 'new' || screen === 'view'"
      class="container container-edit"
    >
      <button class="borderlessBtn" @click.prevent="getState">
        <Icon class="borderlessBtn-icon" icon="bx:chevron-left" />
        <span class="borderlessBtn-text">
          <translate>All tasks</translate>
        </span>
      </button>

      <div class="edit-header">
        <div class="edit-header-left">
          <input
            v-model="taskForm.name"
            :class="{ 'error-title': errors.name }"
            :disabled="screen === 'view'"
            :placeholder="$gettext('Name your task*')"
            class="edit-inputContainer title-text-primary"
            @blur="validateName($event.target.value)"
          >
          <span v-if="errors.name" class="error-text">
            {{ errors.name }}
          </span>
        </div>

        <div class="edit-header-right">
          <date-picker
            v-model="dates"
            :class="[errors.date && 'invalid']"
            :disabled="screen === 'view'"
            :disabled-date="disabledBeforeDate"
            :placeholder="dateRange"
            popup-class="datePicker"
            range
            range-separator="/"
            @blur="validateDates($event.target.value)"
          />

          <span v-if="errors.date" class="error-text">
            {{ errors.date }}
          </span>
        </div>
      </div>

      <div class="edit-content">
        <div
          :class="{ 'error-field': errors.description }"
          class="edit-textareaContainer edit-infoContainer"
        >
          <span v-if="errors.description" class="error-text">
            {{ errors.description }}
          </span>
          <span class="edit-subtitle">
            <translate>General info</translate>
            <span>*</span>
          </span>
          <textarea
            v-model="taskForm.description"
            :disabled="screen === 'view'"
            :placeholder="$gettext('Write general information about the campaign...')"
            @blur="validateDescription($event.target.value)"
          />
        </div>

        <div
          :class="{ 'error-field': errors.must_have }"
          class="edit-textareaContainer edit-haveContainer"
        >
          <span v-if="errors.must_have" class="error-text">
            {{ errors.must_have }}
          </span>
          <span class="edit-subtitle"><translate>Must haves</translate></span>
          <textarea
            v-model="taskForm.must_have"
            :disabled="screen === 'view'"
            :placeholder="$gettext('Write must have point here...')"
            class="textarea--with-point"
            @blur="validateMustHave($event.target.value)"
          />
          <span class="textarea-placeholder-point blue" />
        </div>

        <div
          :class="{ 'error-field': errors.must_not_have }"
          class="edit-textareaContainer edit-haveContainer"
        >
          <span v-if="errors.must_not_have" class="error-text">
            {{ errors.must_not_have }}
          </span>
          <span class="edit-subtitle">
            <translate>Must not haves</translate>
          </span>
          <textarea
            v-model="taskForm.must_not_have"
            :disabled="screen === 'view'"
            :placeholder="$gettext('Write must not have point here...')"
            class="textarea--with-point"
            @blur="validateMustNotHave($event.target.value)"
          />
          <span class="textarea-placeholder-point red" />
        </div>
      </div>

      <div v-if="screen === 'new'" class="edit-controls">
        <BaseButton
          :title="$gettext('Save as draft')"
          color-mode="secondary"
          variant="outline"
          @click="createTask"
        />
        <BaseButton
          :title="$gettext('Save and publish')"
          color-mode="secondary"
          @click="createAndPublishTask"
        />
      </div>

      <div v-if="screen === 'edit'" class="edit-controls">
        <BaseButton
          :title="$gettext('Save changes')"
          color-mode="secondary"
          variant="outline"
          @click="editTask"
        />
        <BaseButton
          :title="$gettext('Save and publish')"
          color-mode="secondary"
          @click="editAndPublishTask"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '@/style/fonts.scss';
  @import '@/style/colors.scss';
  @import '@/style/breakpoints.scss';

  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 !important;

    @include bootstrap-large-lower-boundary {
      padding: 0;
    }

    &-empty {
      height: 100%;
      background-image: url('../../assets/background-folder.png');
      background-position: 50% 30%;
      background-repeat: no-repeat;
    }

    &-edit {
      height: 100%;
    }
  }

  .data {
    &-header {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: 1fr 1fr;
      gap: 20px;

      @include bootstrap-large-lower-boundary {
        width: 100%;
        grid-template-columns: 1fr 40px;
      }
    }

    &-title {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    &-description {
      grid-column: 1 / 2;
      grid-row: 2 / 3;

      @include bootstrap-large-lower-boundary {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
      }
    }

    &-button {
      grid-column: 2 / 3;
      grid-row: 1 / 3;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 15px;

      @include bootstrap-large-lower-boundary {
        grid-row: 1 / 2;
        align-items: flex-start;
      }
    }
  }

  .title {
    &-container {
      display: flex;
      align-items: center;
      gap: 10px;

      @include bootstrap-large-lower-boundary {
        gap: 8px;
      }
    }

    &-text-primary {
      @include raleway-bold;
      font-size: 28px;
      line-height: 36px;
      color: $primary-black;
      margin: 0;

      @include bootstrap-large-lower-boundary {
        font-size: 24px;
      }
    }

    &-text-secondary {
      @include raleway-regular;
      min-width: 20px;
      font-size: 16px;
      color: $primary-grey;
      text-overflow: ellipsis;
      overflow: hidden;
      align-self: flex-start;
      line-height: 36px;
    }
  }

  .description {
    &-text {
      @include raleway-regular;
      font-size: 14px;
      color: $primary-grey;
      margin: 0;
    }
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 10%;
  }

  .task-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .task {
    &-item {
      height: 70px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      border-radius: 8px;
      padding: 0 20px;
      background-color: $primary-white;
    }

    &-data {
      display: flex;
      align-items: center;
      gap: 10px;
      overflow: hidden;
    }

    &-icon {
      width: 40px;
      min-width: 40px;
      height: 40px;
    }

    &-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    &-name {
      @include raleway-semibold;
      font-size: 14px;
      color: $primary-black;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-status {
      @include raleway-regular;
      font-size: 12px;
      color: $primary-grey;
    }

    &-controls {
      display: flex;
      gap: 15px;
      align-items: center;
    }

    &-edit-btn {
      width: 50px !important;
      height: 100%;

      &:hover svg {
        transition: 0.2s;
        color: $primary-blue;
      }
    }

    &-edit-icon {
      height: 20px;
      width: 16px;
      color: $primary-grey;
    }
  }

  // EDIT SCREEN
  .edit {
    &-header {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 20px;

      @include bootstrap-large-lower-boundary {
        grid-template-columns: 1fr;
      }

      &-left {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }

      &-right {
        display: grid;
        column-gap: 15px;
      }
    }

    &-inputContainer {
      @include raleway-semibold;
      flex: 1;
      width: 100%;
      border: 1px solid transparent;
      color: $primary-black;
      background-color: $primary-white;
      padding: 14px;
      border-radius: 16px;
      word-break: break-word;
      font-size: 14px;
      line-height: 20px;
      min-height: 50px;
      height: 50px;
      max-height: 50px;

      &:focus-visible {
        outline: none;
      }

      &::placeholder {
        color: $secondary-white-grey;
      }

      &.error-title {
        border: 1px solid $primary-red;
      }

      & + .error-text {
        padding-top: 8px;
        padding-left: 12px;
      }
    }

    &-content {
      height: 100%;
      display: grid;
      grid-template-rows: 1fr 2fr;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      @include bootstrap-large-lower-boundary {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
      }
    }

    &-textareaContainer {
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 16px;
      padding: 20px;
      background-color: $primary-white;
      caret-color: $primary-blue;

      & textarea {
        all: unset;
        @include raleway-regular;
        height: 100%;
        font-size: 14px;
        line-height: 20px;
        color: $primary-black;
        margin-top: 10px;

        &::placeholder {
          color: $secondary-white-grey;
          white-space: pre-line;
        }
      }

      .textarea--with-point {
        &::placeholder {
          padding-left: 12px;
        }
      }

      .textarea-placeholder-point {
        position: absolute;
        top: 56px;
        left: 20px;
        display: none;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        background: $primary-black;
        pointer-events: none;

        &.blue {
          background: $primary-blue;
        }

        &.red {
          background: $primary-red;
        }
      }

      .textarea--with-point:placeholder-shown + .textarea-placeholder-point {
        display: block;
      }
    }

    &-infoContainer {
      height: 100%;
      grid-column: 1 / 3;

      @include bootstrap-large-lower-boundary {
        grid-column: 1 / 2;
      }
    }

    &-haveContainer {
      height: 100%;
    }

    &-subtitle {
      @include raleway-bold;
      font-size: 16px;
      color: $primary-black;
    }

    &-controls {
      width: max-content;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
      align-self: flex-end;

      @include bootstrap-large-lower-boundary {
        width: 100%;
      }
    }
  }

  .borderlessBtn {
    width: max-content;
    display: flex;
    align-items: center;
    padding: 0;

    &-icon {
      height: 20px;
      width: 20px;
    }

    &-text {
      @include raleway-semibold;
      font-size: 14px;
      color: $primary-grey;
    }

    &-underline {
      text-decoration: underline;
      text-underline-offset: 2px;
      color: $primary-blue;

      & span {
        color: $primary-blue;
      }
    }
  }

  .container-loading {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    background-color: $primary-background;
    opacity: 0.7;
  }

  .error {
    &-title {
      color: $primary-red;
    }

    &-text {
      @include raleway-regular;
      font-size: 14px;
      line-height: 20px;
      color: $primary-red;
    }

    &-field {
      outline: 1px solid $primary-red;
    }
  }

  // DROPDOWN
  .black:deep(a) {
    color: $primary-black;
  }

  .black:deep(a) svg {
    color: $primary-black;
  }

  .red:deep(a) {
    color: $primary-red;
  }

  .red:deep(a) svg {
    color: $primary-red;
  }

  .blue:deep(a) {
    color: $primary-blue;
  }

  .blue:deep(a) svg {
    color: $primary-blue;
  }
</style>

<style lang="scss">
  @import '@/style/fonts.scss';
  @import '@/style/colors.scss';

  // DATE
  .datePicker {
    border-radius: 16px;
    color: $primary-black;
  }

  .mx-calendar-header button {
    color: $primary-black;
  }

  .mx-input {
    @include roboto-regular;
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    font-size: 14px;
    border: 1px solid transparent;
    white-space: nowrap;
    color: $primary-black;
    background-color: $primary-white;
    box-shadow: none;

    &:focus,
    &:active,
    &:focus-visible,
    &:active:focus,
    &.active:focus {
      box-shadow: none;
      outline: none;
      border: 1px solid transparent;
    }

    &.active {
      background-color: $primary-black;
    }

    &:active,
    &:hover {
      color: $primary-black;
      border: 1px solid transparent;
    }
  }

  .mx-datepicker {
    width: 100%;

    &.invalid .mx-input {
      border: 1px solid $primary-red !important;
    }

    svg {
      color: $primary-black;
    }
  }
</style>
