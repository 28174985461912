export const endpoints = {
  common: {
    currencies: '/api/common/currencies/',
    countries: '/api/common/countries/',
    city: '/api/common/city',
  },
  campaign: {
    technical_description: '/api/adv/campaign_technical_descriptions/',
    offer: '/api/adv/offer/',
    campaign_formats: '/api/adv/campaign_kinds/',
    create_campaign: '/api/adv/create_campaign/',
  },
  finance: {
    account_balance: '/api/billing/advertiser_account_balance/',
    payment_providers: '/api/billing/providers/?pay_in=1',
    payment_methods: '/api/billing/payment_methods/',
    deposit: '/api/billing/deposit/',
  },
  agent_campaign: '/api/adv/agent_campaign/',
  influencer: {
    is_exists: '/api/adv/influencer_exists/',
    add: '/api/adv/add_influencer',
  },
};
