<script>
import { Icon } from '@iconify/vue2';

export default {
  name: 'BaseLinkButton',
  components: { Icon },
  props: {
    disabled: { type: Boolean, default: false },
    customStyles: { type: String, default: '' },
    icon: { type: String, required: false, default: null },
    label: { type: String, required: true },
    variant: { type: String, default: '', validator: (value) => ['primary', ''].includes(value) },
  },
  computed: {
    buttonClass() {
      return {
        'base-link-button': true,
        'base-link-button--primary': this.variant === 'primary',
      };
    },
  },
};
</script>

<template>
  <button :class="buttonClass" :disabled="disabled" :style="customStyles" @click="$emit('click')">
    <Icon v-if="icon" :icon="icon" class="base-link-button__icon" width="16px" />
    <translate style="text-transform: capitalize">
      {{ label }}
    </translate>
  </button>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .base-link-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    color: colors.$primary-grey;
    @include fonts.fontSize-normal;
    @include fonts.raleway-semibold;

    &:hover {
      text-decoration: underline;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      text-decoration: none;
    }

    &--primary {
      color: colors.$brand-color;
    }
  }
</style>
