<script>
import { Icon } from "@iconify/vue2";
import { v4 as generatedId } from "uuid";

export default {
  components: {
    Icon,
  },
  props: {
    leftBtnTooltipText: {
      type: String,
      required: false,
    },
    rightBtnTooltipText: {
      type: String,
      required: false,
    },
  },
  computed: {
    leftBtnTooltipId() {
      return generatedId();
    },
    rightBtnTooltipId() {
      return generatedId();
    },
  },
  emits: ["onLeftBtnClick", "onRightBtnClick"],
};
</script>

<template>
  <div>
    <div class="pills">
      <button
        :id="leftBtnTooltipId"
        class="pills-btn"
        @click.prevent="$emit('onLeftBtnClick')"
      >
        <Icon icon="mdi:ban" width="20" />
        <b-tooltip
          v-if="leftBtnTooltipText"
          :target="leftBtnTooltipId"
          triggers="hover"
        >
          {{ leftBtnTooltipText }}
        </b-tooltip>
      </button>

      <button
        :id="rightBtnTooltipId"
        class="pills-btn"
        @click.prevent="$emit('onRightBtnClick')"
      >
        <Icon icon="bx-refresh" width="20" />
        <b-tooltip
          v-if="rightBtnTooltipText"
          :target="rightBtnTooltipId"
          triggers="hover"
        >
          {{ rightBtnTooltipText }}
        </b-tooltip>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/colors.scss";
@import "@/style/tooltip.scss";

.pills {
  width: max-content;
  display: flex;
  gap: 2px;
  border-radius: 16px;
  overflow: hidden;

  &-btn {
    height: 40px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-background;

    &:not(:disabled):active,
    &:not(:disabled):hover {
      background: $primary-background-dark;
    }
  }
}
</style>
