<script>
import { Icon } from "@iconify/vue2";
import { BaseMenu, TheUser } from "@/components/menu";
import { BaseModalContainer } from "@/components/ui";
import { BalanceWidget } from "@/components/finance";

export default {
  components: {
    BalanceWidget,
    BaseMenu,
    BaseModalContainer,
    Icon,
    TheUser,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleUserRedirect() {
      if (this.$route.path !== "/settings") {
        this.$router.push({ name: "settings" });
      }

      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>

<template>
  <BaseModalContainer :modalId="modalId" :hideHeader="false">
    <div class="wrapper">
      <div class="userContainer">
        <TheUser @on-click="handleUserRedirect" />
        <button class="userContainer-btn" @click.prevent="handleUserRedirect">
          <Icon icon="bx-chevron-right" />
        </button>
      </div>

      <BalanceWidget style="width: 100%" />

      <BaseMenu @on-click="$bvModal.hide(modalId)" />

      <span class="credits">© 2022 ADVY</span>
    </div>
  </BaseModalContainer>
</template>

<style lang="scss" scoped>
@import "@/style/colors.scss";
@import "@/style/fonts.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 24px;
}

.userContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  &-btn {
    height: 50px;
    width: 30px;

    & svg {
      height: 20px;
      width: 20px;
      color: $secondary-white-grey;
    }
  }
}

.credits {
  @include raleway-medium;
  font-size: 12px;
  line-height: 34px;
  color: #b9b9b9;
  padding-left: 12px;
}

:deep(.dropdownButton) {
  width: 100%;
}

:deep(.dropdownButton-pill) {
  width: 100%;
}
</style>